/* ------- INTERNAL URLS STARTS HERE -------- */

// AUTO LOGIN
export const AUTO_LOGIN_URL = "/auto-login";

// HOME URLS
export const HOME_URL = "/home";

// SERP RESULT URLS
export const SERP_RESULT_URL = "/serp-result";

// KEYWORD OPTIMIZATION URLS
export const KEYWORD_OPTIMIZATION_URL = "/:tenantCode/optimize-keyword/keyword-optimization";
export const KEYWORD_OPTIMIZATION_THEME_URL =
    "/:tenantCode/optimize-keyword/keyword-optimization/t/:themeID/b/:selectedBrandingType/k/:selectedKeyword";

// KEYWORD ANALYSIS URLS
export const KEYWORD_ANALYSIS_URL = "/:tenantCode/keyword-analysis/:locale/:keyword/:domain?";

// TECH AUDIT URLS
export const TECH_AUDIT_URL = "/:tenantCode/audit/technical-audit";

// SIMULATOR URLS
export const SIMULATOR_URL = "/simulation";

// PROJECT SETUP URL
export const PROJECT_SETUP = "/setup";
export const PROJECT_SETUP_DOMAIN_DETAILS_URL = "/setup/domain-details";
export const PROJECT_SETUP_COMPETITORS_URL = "/setup/competitors";
export const PROJECT_SETUP_INTEGRATIONS_URL = "/setup/integrations";
export const PROJECT_SETUP_ADD_KEYWORDS_URL = "/setup/add-keywords";
export const PROJECT_SETUP_MANAGE_KEYWORDS_URL = "/setup/manage-keywords";
export const PROJECT_SETUP_WEBMASTER_CALLBACK_URL = "/setup/gsc/oauth2CloseCallback";
export const PROJECT_SETUP_GOOGLE_ADS_CALLBACK_URL = "/setup/googleads/oauth2CloseCallback";


// COMPETITIVE INTELLIGENCE URLS
export const COMPETITIVE_INTELLIGENCE_URL = "/competitive-intelligence";
export const CI_SHARE_OF_VOICE_URL = "/competitive-intelligence/share-of-voice";
export const CI_RANK_DISTRIBUTION_URL = "/competitive-intelligence/rank-distribution";
export const CI_TOPICAL_AUTHORITY_URL = "/competitive-intelligence/topical-authority";
export const CI_PAGE_AUTHORITY_URL = "/competitive-intelligence/page-authority";
export const CI_TECHNICAL_METRICS_URL = "/competitive-intelligence/technical-metrics";
export const CI_ALPS_SCORES_URL = "/competitive-intelligence/alps-scores";

// CUSTOM REPORTING URLS
export const CUSTOM_REPORTING_URL = "/custom-reporting";
export const CUSTOM_REPORTING_EDIT_URL = "/custom-reporting/edit";
export const CUSTOM_REPORTING_VIEW_URL = "/custom-reporting/view";

// OPTIMIZATION
export const OPTIMIZATION_URL = "/optimization";
export const KEYWORD_GAP_ANALYSIS_URL = "/home";
export const PAGE_OPTIMIZER_URL = "/page-optimizer";

// CONTENTBRIEF
export const CONTENT_BRIEF = "/content-brief";
export const CONTENT_BRIEF_NEW = "/content-brief-new";
export const CONTENT_BRIEF_VIEW = "/content-brief-view";

// PLANNING AND RESEARCH URLS
export const COMPETITIVE_RESEARCH_URL = "/competitive-research";
export const CR_SHARE_OF_VOICE_URL = "/competitive-research/share-of-voice";
export const CR_SHARE_OF_VOICE_OVERVIEW_URL = "/competitive-research/share-of-voice/overview";
export const CR_SHARE_OF_VOICE_CATEGORY_URL = "/competitive-research/share-of-voice/category";
export const CR_TOPICAL_AUTHORITY_URL = "/competitive-research/topical-authority";
export const CR_TOPICAL_AUTHORITY_CATEGORY_URL = "/competitive-research/topical-authority/category";
export const CR_TOPICAL_AUTHORITY_DOMAIN_URL = "/competitive-research/topical-authority/domain";
export const CR_ALPS_SCORE_ANALYSIS_URL = "/competitive-research/alps-score-analysis";
export const CR_ALPS_SCORE_ANALYSIS_SCORE_TRENDS_URL = "/competitive-research/alps-score-analysis/score-trends";
export const CR_ALPS_SCORE_ANALYSIS_GAP_ANALYSIS_URL = "/competitive-research/alps-score-analysis/gap-analysis";
export const CR_DATA_ON_DEMAND_URL = "/competitive-research/data-on-demand";

// PERFORMANCE_REPORT_URLS
export const PRS_PERFORMANCE_REPORTS_URL = "/performance-reports";
export const PRS_RANK_TRACKING_URL = "/performance-reports/rank-tracking";
export const PRS_RANK_TRACKING_KEYWORD_RANK_URL = "/performance-reports/rank-tracking/keyword-performance";
export const PRS_RANK_TRACKING_PAGES_REPORT_URL = "/performance-reports/rank-tracking/page-performance";
export const PR_GSC_URL = "/performance-reports/gsc-analytics";
export const PR_GSC_KEYWORD_DETAILS_URL = "/performance-reports/gsc-analytics/keyword-details-report";
export const PR_GSC_PAGE_ANALYSIS_URL = "/performance-reports/gsc-analytics/page-analysis-report";
export const PR_GSC_MASTERVIEW = "/performance-reports/gsc-analytics/masterview";
export const PR_GSC_KEYWORD_URL = "/performance-reports/gsc-analytics/keyword-url";
export const PR_GSC_KEYWORD_ONLY = "/performance-reports/gsc-analytics/keyword-only";
export const PR_GSC_URL_ONLY = "/performance-reports/gsc-analytics/url-only";
export const PRS_PROJECT_DASHBOARD_URL = "/performance-reports/project-dashboard";
export const PRS_PROJECT_DASHBOARD_PERFORMANCE_DASHBOARD_URL = "/performance-reports/project-dashboard/performance-dashboard";
export const PRS_PERFORMANCE_IMPACT_URL = "/performance-reports/page-visibility-tracker";
export const PRS_PERFORMANCE_IMPACT_REPORT_URL = "/performance-reports/page-visibility-tracker/report";
export const SERP_FEATURE_COVERAGE_URL = "/performance-reports/serp-feature-coverage";
// PROJECT URLS
export const PROJECT_URL = "/project";

// KRT URLS
export const KEYWORD_RESEARCH_HOME_URL = "/optimization-tool/keyword-research-home";
export const KEYWORD_RESEARCH_TOOL_URL = "/optimization-tool/keyword-research-tool";

/* ------- INTERNAL URLS ENDS HERE -------- */

/* ------- EXTERNAL/ANGULAR URLS STARTS HERE -------- */

// PROJECT URLS
export const REPORTS_DASHBOARD_URL = "/:tenantCode/reporting/dashboard";
export const PROJECT_AUDIT_URL = "/:tenantCode/audit";
export const OPPORTUNITY_PLANNING_URL = "/:tenantCode/competitive-research/theme-prioritization";

// PROJECT AUDIT SUB URLS
export const BACKLINK_ANALYSIS_URL = "/:tenantCode/audit/backlink-audit";

/* ------- EXTERNAL/ANGULAR URLS ENDS HERE -------- */

/* ----------- LOGIN URLS -------------- */
export const CHANGE_PASSWORD = "/:tenantCode/changepassword";
export const USER_LOGOUT_URL = "/:tenantCode/logout";
export const CHANGE_ACCOUNT = "/:tenantCode/change-account";
export const USER_LOGIN = "/login";
export const USER_LOGIN_HOME = "/";
export const SSO_CALLBACK = "/sso/callback/*";
export const USER_FORGOT_PASSWORD = "/forgotpassword";
export const USER_RESET_PASSWORD = "/resetpassword";
