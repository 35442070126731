import { AnyAction } from "redux";
import { call, put, takeEvery } from "redux-saga/effects";
import Apis from "./apis";
import actionTypes, {
    callToGetChangePwdStatusCode,
    callToGetResetPwdStatusCode,
    setErrMsg,
    setLogginIn,
    setSSOUser,
    setTenantList,
    setUserLogoutStatus,
    callToGetForgotPwdStatusCode,
    setUserId,
    setCognitoClientID,
    setCognitpAPI,
    setIntmdId
} from "./actions";
import { getContext } from "../../app/duck/context-container";
import { storeUserDetailsInLocalStorage, storeUserDetailsInSessionCookie, updateTenantListInLocalStorage } from "../../../src/utils";
import { ALPS_LOCAL_STORAGE_KEY } from "../../app/const";

// tslint:disable-next-line:typedef
function* authChangeTenant(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const payload = {
        change_tenant: action.payload.change_tenant,
        current_tenant: action.payload.current_tenant
    };
    const response = yield call(Apis.changeTenant, payload, parser);
    if (response.status === 200) {
        storeUserDetailsInLocalStorage(response.data);
        storeUserDetailsInSessionCookie(response.data);
        window.location.pathname = "/page-optimizer";
    } else {
        yield put(setErrMsg("Oops!! something went wrong"));
    }
}

// tslint:disable-next-line:typedef
function* authUserLogout(action: AnyAction) {
    const payload = { isResetPassword: action.payload.isResetPassword };
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    yield call(Apis.logoutUser, parser);
    yield put(setUserLogoutStatus(true));
    localStorage.removeItem("tenant_list");
    localStorage.removeItem(ALPS_LOCAL_STORAGE_KEY);
    localStorage.removeItem("featureAddOns");
    document.cookie = `${process.env.COOKIE_SESSION_ID} = ; ${"max-age"} = ${-1}; ${"path"} = ${"/"};`;
    sessionStorage.removeItem("state");
    if (payload.isResetPassword) {
        const url = new URL("/login", window.location.origin);
        url.searchParams.set("cp", "true");
        window.location.href = url.toString();
    } else {
        window.location.pathname = "/login";
    }
}

// tslint:disable-next-line:typedef
function* checkSSOUserLogin(action: AnyAction) {
    const payload = { email: action.payload.email };
    const response = yield call(Apis.checkSSOLogin, payload);
    if (response.status === 200) {
        if (response.data.sso_enabled) {
            yield put(setCognitoClientID(response.data.cognito_client_id));
            yield put(setCognitpAPI(response.data.cognito_api));
            yield put(setSSOUser(true));
            yield put(setLogginIn(false));
        } else {
            yield put(setErrMsg("SSO not enabled"));
            yield put(setSSOUser(false));
            yield put(setLogginIn(false));
        }
    } else {
        yield put(setErrMsg("Oops!! something went wrong"));
        yield put(setSSOUser(false));
        yield put(setLogginIn(false));
    }
}

// tslint:disable-next-line:typedef
function* userLogin(action: AnyAction) {
    const payload = {
        user_email: action.payload.email,
        user_password: action.payload.password
    };
    const response = yield call(Apis.userLogin, payload);
    yield put(setLogginIn(false));
    if (response.status === 200) {
        storeUserDetailsInLocalStorage(response.data);
        storeUserDetailsInSessionCookie(response.data);
        window.location.pathname = "/page-optimizer";
    } else if (response.status === 202) {
        const tenantList = response.data.tenant_code;
        const userId = response.data.user_id;
        const intmdId = response.data.intermediate_id;
        yield put(setTenantList(tenantList));
        yield put(setUserId(userId));
        yield put(setIntmdId(intmdId));
        updateTenantListInLocalStorage(tenantList);
    } else if (response.status === 403) {
        if (response.response.attempts !== "Invalid Credentials") {
            yield put(
                setErrMsg(
                    `${response.response.attempts} Please click on <a href='/forgotpassword'>Forgot Password</a> to reset your password.`
                )
            );
        } else {
            yield put(
                setErrMsg("Invalid Credentials. Please click on <a href='/forgotpassword'>Forgot Password</a> to reset your password.")
            );
        }
    } else if (response.status === 401) {
        yield put(
            setErrMsg(
                "Your account has been blocked due to multiple invalid login attempts. Please click on forgot password and reset your password."
            )
        );
    } else {
        yield put(setErrMsg("Oops!! something went wrong"));
    }
}

// tslint:disable-next-line:typedef
function* userLoginWithTenant(action: AnyAction) {
    const payload = {
        user_id: action.payload.user_id,
        tenant_code: action.payload.tenant_code,
        intermediate_id: action.payload.intmd_id
    };
    const response = yield call(Apis.userLoginWithTenant, payload);
    if (response.status === 200) {
        storeUserDetailsInLocalStorage(response.data);
        storeUserDetailsInSessionCookie(response.data);
        window.location.href = `${process.env.ROOT_BASE_URL}/page-optimizer`;
    } else {
        yield put(setErrMsg("Oops!! something went wrong"));
        window.location.href = `${process.env.ROOT_BASE_URL}/login`;
    }
}

// tslint:disable-next-line:typedef
function* userLoginSSO(action: AnyAction) {
    const payload = {
        grant_code: action.payload.code
    };
    const response = yield call(Apis.userSSOLogin, payload);
    if (response.status === 200) {
        storeUserDetailsInLocalStorage(response.data);
        storeUserDetailsInSessionCookie(response.data);
        window.location.href = `${process.env.ROOT_BASE_URL}/page-optimizer`;
    } else if (response.status === 202) {
        const tenantList = response.data.tenant_code;
        const userId = response.data.user_id;
        const intmdId = response.data.intermediate_id;
        yield put(setUserId(userId));
        yield put(setTenantList(tenantList));
        yield put(setIntmdId(intmdId));
        updateTenantListInLocalStorage(tenantList);
    } else if (response.status === 403) {
        yield put(setErrMsg("Invalid Credentials"));
    } else if (response.status === 400) {
        yield put(setErrMsg(response.response.err));
    } else {
        yield put(setErrMsg("Oops!! something went wrong"));
    }
}

// tslint:disable-next-line:typedef
function* authUserForgotPwd(action: AnyAction) {
    const payload = {
        user_email: action.payload.user_email,
        token: action.payload.token
    };
    const response = yield call(Apis.userForgotPwd, payload);
    if (response.status === 200) {
        yield put(callToGetForgotPwdStatusCode(response.status));
    } else {
        yield put(callToGetForgotPwdStatusCode(response.status));
    }
}

// tslint:disable-next-line:typedef
function* authUserChangePwd(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const payload = {
        password: action.payload.password,
        new_password: action.payload.new_password
    };
    const response = yield call(Apis.userChangePwd, payload, parser);
    if (response.status === 200) {
        const url = new URL(`/${tenantCode}/logout`, window.location.origin);
        url.searchParams.set("cp", "true");
        window.location.href = url.toString();
    } else {
        yield put(callToGetChangePwdStatusCode(response.status));
    }
}

// tslint:disable-next-line:typedef
function* authUserResetPwd(action: AnyAction) {
    const payload = {
        password: action.payload.password,
        session_key: action.payload.session_key
    };
    const response = yield call(Apis.userResetPwd, payload);
    if (response.status === 200) {
        yield put(callToGetResetPwdStatusCode(response.status));
    } else if (response.status === 403) {
        yield put(callToGetResetPwdStatusCode(response.status));
    } else {
        yield put(callToGetResetPwdStatusCode(response.status));
    }
}

// tslint:disable-next-line: typedef
export function* watchChangeuserTenant() {
    yield takeEvery(actionTypes.CALL_TO_CHANGE_TENANT, authChangeTenant);
}

// tslint:disable-next-line: typedef
export function* watchUserLogout() {
    yield takeEvery(actionTypes.CALL_USER_LOGOUT, authUserLogout);
}

// tslint:disable-next-line: typedef
export function* watchCheckSSOUserLogin() {
    yield takeEvery(actionTypes.CHECK_SSO_USER_LOGIN, checkSSOUserLogin);
}

// tslint:disable-next-line: typedef
export function* watchUserLogin() {
    yield takeEvery(actionTypes.USER_LOGIN, userLogin);
}

// tslint:disable-next-line: typedef
export function* watchUserLoginWithTenant() {
    yield takeEvery(actionTypes.USER_LOGIN_WITH_TENANT, userLoginWithTenant);
}

// tslint:disable-next-line: typedef
export function* watchUserLoginSSO() {
    yield takeEvery(actionTypes.CALL_USER_LOGIN_SSO, userLoginSSO);
}

// tslint:disable-next-line: typedef
export function* watchAuthUserForgotPwd() {
    yield takeEvery(actionTypes.USER_FORGOT_PWD, authUserForgotPwd);
}

// tslint:disable-next-line: typedef
export function* watchAuthUserChangePwd() {
    yield takeEvery(actionTypes.USER_CHANGE_PWD, authUserChangePwd);
}

// tslint:disable-next-line: typedef
export function* watchAuthUserResetPwd() {
    yield takeEvery(actionTypes.USER_RESET_PWD, authUserResetPwd);
}
