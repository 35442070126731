export interface ProjectDetailsInterface {
    domain_url: string;
    name: string;
    sub_domains_value: string;
    sub_domain: string[];
    is_default_project: boolean;
}

export interface SubDomainDetailsInterface {
    id: number;
    tenant_code: string;
    project: string;
    sub_domain_url: string;
}
export interface ProjectAndSubDomainDetailsInterface {
    domain_url: string;
    name: string;
    sub_domains_value: string;
    sub_domain: SubDomainDetailsInterface[];
    is_default_project: boolean;
    site_url: null | string;
    valid_webmaster: null | string;
    webmaster_email: null | string;
    customer_ids: {
        customer_id: string,
        customer_name: string,
        mcc_account_id: string,
        mcc_account_name: string,
    } | null;
    googleads_email: string | null;
    valid_googleads: boolean | null;
}

export interface CreateProjectApiPayloadInterface {
    domain_url: string;
    name: string;
    locale: string;
    is_default_project: boolean;
    is_mobile: boolean;
    crawling_frequency: string;
}

export interface CompetitorsInterface {
    id: number;
    tenant_code: string;
    project: number;
    competitor_url: string;
    competitor_name: string;
}
export interface ProjectSetupReducerInterface {
    showProjectSetupNavbar: boolean;
    isAddKeywordsSuccess: boolean;
    headerText?: string;
    projectJourneyFlowType: string;
    locale: string;
    projectID?: number;
    projectDetails: ProjectAndSubDomainDetailsInterface;
    keywordDetails?: KeywordDetailsInterface[];
    displayKeywordDetails: KeywordDetailsInterface[];
    siteURLSList: string[];
    customerIdsList: CustomerIdsListDataInterface[];
    categoryList: CategoryInterface[];
    addedKeywordsIDs: number[];
    listOfOptimizedKeywords: string[];
    filterKeywordDetails?: KeywordDetailsInterface[];
    // tslint:disable-next-line:no-any
    selectedCategories: any[];
    isCategoryAlreadyPresent: boolean;
    selectedKeywordsIDs: number[];
    marketingFunnelList: string[];
    targetUrlList: string[];
    competitorsList: CompetitorsInterface[];
    preSavedCompetitors: string[];
    suggestedCompetitorDomains: string[];
    isSuggestedCompetitorDomainsAPICalled: boolean;
    statusCodeOfSaveCompetitorAPi?: number | null;
    isCategoryAddSuccessInKwResearch?: boolean;
    isOptimizationKeywordSuccess?: boolean;
    isAddKwLimitExceeded?: string;
    statusCodeOfSubmitSiteurlAPi?: number | null;
    statusCodeOfSubmitCustomerIdsAPi?: number | null;
    mkActiveNotificationList: ActiveNotificationInterface[];
    addKeywordNotifications: ActiveNotificationInterface[];
    file?: File | null;
    fileUploadStatus?: boolean;
    fileUploadMessage?: string;
    statusCodeOfCreateProjectAPI?: number | null;
    isMobile: boolean;
    initialDeviceOption?: boolean;
    errorFileUploadStatus?: boolean;
    // tslint:disable-next-line:no-any
    errorFileApiResponse?: any[];
    crawlFreq?: string;
    initialCrawlFreq: string;
}

export interface KwLimitReachedResponseInterface {
    code: string;
    limit: number;
}
export interface MgKwAppliedFiltersInterface {
    includeFilter: { filterType: string; values: string[] };
    excludeFilter: string[];
    brandType?: string;
    marketingFunnel: string[];
    targetUrl: string[];
}

export interface KeywordsDetailByThemeInterface {
    [key: string]: ThemeDetailsInterface[];
}

export interface ThemeDetailsInterface {
    keyword: string;
    keywordID: number;
    targetURL: string | null;
}

export interface CategoryInterface {
    id: number;
    category: string;
    keywordCount?: number;
    project?: number;
}

export interface UpdateProjectApiPayloadInterface {
    name: string;
    is_default_project: boolean;
    is_mobile: boolean;
}

export interface CreateSubDomainsApiPayloadInterface {
    sub_domains: string[];
    projectID: number;
}

export interface DeleteSubDomainsApiPayloadInterface {
    ids: number[];
    projectID: number;
}

export interface GetProjectDetailsApiPayloadInterface {
    projectID: number;
}
export interface SaveProjectKeywordsInterface {
    project_id: number;
    keywords: string[];
}

export interface KeywordDetailsInterface {
    id: string;
    keyword: string;
    keywordType?: string;
    targetURL: string | null;
    theme: string | null;
    categories?: number[];
    intent: string | null;
}

export interface MangeKeywordTableBodyRowInterface extends Omit<KeywordDetailsInterface, "categories"> {
    categories?: CategoryInterface[];
    keywordID: string;
    keywordDetail?: KeywordDetailsInterface;
}

export interface ManageKeywordThemeViewTableBodyRowInterface {
    keyword?: string;
    targetURL?: string | null;
    theme: string | null;
    groupedKeywordsByTheme?: KeywordsDetailByThemeInterface;
}

export interface ActiveNotificationInterface {
    name: string;
    autoClose: boolean;
    message: string;
    type: string;
}

export enum ManageKeywordNotifications {
    ADD_CATEGORY_TOAST = "showIsAddCategoryToast",
    THEME_VIEW_TOAST = "showThemeViewToast",
    KEYWORD_TYPE_TOAST = "showKeywordTypeToast",
    DATA_STILL_PROCESSING_TOAST = "showDataStillProcessingToast",
    TARGET_URL_ASSIGN_TOAST = "showTargetUrlAssignSuccessToast",
    DELETE_KEYWORD_TOAST = "showDeleteKwToast",
    CLEAR_ALL = "clearAll",
    KEYWORD_MAPPED_AND_OPTIMIZED = "showKeywordMappedAndOptimized",
    MAP_KWRD_AND_CATEGORY = "showMapKeywordCategoryToast",
    ADD_TO_OPTIMIZATION_KEYWORD = "showAddToOptimizationKeywordToast",
    DOWNLOAD_KEYWORD_DETAILS = "showDownloadKeywordDetailsToast",
    CALL_TO_SAVE_UPLOAD_PROJECT_KEYWORD = "showCallToSaveUploadProjectKeyword"
}

export interface ValidCompetitorsContextInterface {
    validCompetitorDomains: string[];
    setValidCompetitorDomains: React.Dispatch<React.SetStateAction<string[]>>;
    handleRemoveCompetitorDomains(domain: string, index?: number): void;
}

export interface CustomerIdsListDataInterface {
    name: string;
    id: string;
    manager: boolean;
    children: CustomerIdsListDataInterface[] | null;
}
