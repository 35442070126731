import { handleActions } from "redux-actions";
import { AnyAction } from "redux";
import actionTypes from "./actions";
import { ProjectSetupReducerInterface } from "./types";
import { assign } from "lodash";
import {
    setKeywordDetails,
    setFilterKeyword,
    getMarketingFunnelValues,
    getTargetUrlist,
    setAddedKeywordIDs,
    setListOfOptimizedKeywords,
    updateMkNotificationList,
    updateAddKeywordsNotificationList,
    setListOfPreSavedCompUrls
} from "./utils";

export const INIT_STATE: ProjectSetupReducerInterface = {
    projectID: undefined,
    showProjectSetupNavbar: false,
    isAddKeywordsSuccess: false,
    headerText: undefined,
    projectJourneyFlowType: "CREATE",
    locale: "",
    keywordDetails: undefined,
    displayKeywordDetails: [],
    categoryList: [],
    addedKeywordsIDs: [],
    listOfOptimizedKeywords: [],
    isCategoryAlreadyPresent: false,
    projectDetails: {
        domain_url: "",
        name: "",
        sub_domains_value: "",
        sub_domain: [],
        is_default_project: false,
        site_url: null,
        valid_webmaster: null,
        webmaster_email: null,
        customer_ids: null,
        googleads_email: null,
        valid_googleads: null
    },
    siteURLSList: [],
    customerIdsList: [],
    filterKeywordDetails: [],
    selectedCategories: [],
    selectedKeywordsIDs: [],
    marketingFunnelList: [],
    targetUrlList: [],
    competitorsList: [],
    preSavedCompetitors: [],
    suggestedCompetitorDomains: [],
    isSuggestedCompetitorDomainsAPICalled: false,
    statusCodeOfSaveCompetitorAPi: null,
    isCategoryAddSuccessInKwResearch: undefined,
    isAddKwLimitExceeded: undefined,
    statusCodeOfSubmitSiteurlAPi: null,
    statusCodeOfSubmitCustomerIdsAPi: null,
    mkActiveNotificationList: [],
    isOptimizationKeywordSuccess: undefined,
    addKeywordNotifications: [],
    statusCodeOfCreateProjectAPI: null,
    file: null,
    fileUploadStatus: undefined,
    fileUploadMessage: undefined,
    isMobile: false,
    initialDeviceOption: undefined,
    errorFileUploadStatus: undefined,
    errorFileApiResponse: undefined,
    crawlFreq: undefined,
    initialCrawlFreq: "none"
};

const Reducer = handleActions(
    {
        [actionTypes.SET_MK_NOTIFICATION_DETAILS]: (state, action: AnyAction) => ({
            ...state,
            mkActiveNotificationList: updateMkNotificationList(state.mkActiveNotificationList, action.payload)
        }),
        [actionTypes.SET_ADD_KW_NOTIFICATION_DETAILS]: (state, action: AnyAction) => ({
            ...state,
            addKeywordNotifications: updateAddKeywordsNotificationList(state.addKeywordNotifications, action.payload)
        }),
        [actionTypes.SET_IS_ADD_KEYWORD_LIMIT_EXCEEDED]: (state, action: AnyAction) => ({
            ...state,
            isAddKwLimitExceeded: action.payload
        }),
        [actionTypes.SET_PROJECT_SETUP_SELECTED_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            selectedKeywordsIDs: action.payload
        }),
        [actionTypes.SET_PROJECT_SETUP_SELECTED_CATEGORIES]: (state, action: AnyAction) => ({
            ...state,
            selectedCategories: action.payload
        }),
        [actionTypes.SET_SHOW_PROJECT_SETUP_HEADER]: (state, action: AnyAction) => ({
            ...state,
            showProjectSetupNavbar: action.payload
        }),
        [actionTypes.SET_KEYWORDS_ADDED_SUCCESS]: (state, action: AnyAction) => ({
            ...state,
            isAddKeywordsSuccess: action.payload
        }),
        [actionTypes.SET_PROJECT_SETUP_HEADER_TEXT]: (state, action: AnyAction) => ({
            ...state,
            headerText: action.payload
        }),
        [actionTypes.SET_PROJECT_CREATE_JOURNEY]: (state, action: AnyAction) => ({
            ...INIT_STATE
        }),
        [actionTypes.SET_PROJECT_EDIT_JOURNEY]: (state, action: AnyAction) => ({
            ...INIT_STATE,
            projectID: action.payload.id as number,
            projectJourneyFlowType: "EDIT",
            headerText: state.headerText,
            showProjectSetupNavbar: state.showProjectSetupNavbar
        }),
        [actionTypes.SAVE_PROJECT_DETAILS]: (state, action: AnyAction) => ({
            ...state,
            initialDeviceOption: action.payload.is_mobile,
            locale: action.payload.locale,
            headerText: action.payload.name,
            projectDetails: assign({}, state.projectDetails, {
                domain_url: action.payload.domain_url,
                name: action.payload.name,
                is_default_project: action.payload.is_default_project,
                site_url: action.payload.site_url,
                valid_webmaster: action.payload.valid_webmaster,
                webmaster_email: action.payload.webmaster_email,
                customer_ids: action.payload.customer_ids,
                googleads_email: action.payload.googleads_email,
                valid_googleads: action.payload.valid_googleads
            })
        }),
        [actionTypes.RESET_DOMAIN_DETAILS_STATE_STATUS]: (state, action: AnyAction) => ({
            ...state,
            statusCodeOfCreateProjectAPI: null
        }),
        [actionTypes.SAVE_KEYWORD_DETAILS]: (state, action: AnyAction) => ({
            ...state,
            keywordDetails: setKeywordDetails(action.payload),
            marketingFunnelList: getMarketingFunnelValues(action.payload),
            targetUrlList: getTargetUrlist(action.payload)
        }),
        [actionTypes.SAVE_LIST_OF_OPTIMIZED_KEYWORDS]: (state, action: AnyAction) => ({
            ...state,
            listOfOptimizedKeywords: setListOfOptimizedKeywords(action.payload)
        }),
        [actionTypes.SET_FILTER_KEYWORD]: (state, action: AnyAction) => ({
            ...state,
            // @ts-ignore:next-line
            filterKeywordDetails: setFilterKeyword(state.selectedCategories, state.keywordDetails)
        }),
        [actionTypes.SAVE_SUBDOMAIN_LIST]: (state, action: AnyAction) => ({
            ...state,
            projectDetails: assign({}, state.projectDetails, {
                sub_domain: action.payload
            })
        }),
        [actionTypes.SAVE_ADDED_KEYWORD_IDS]: (state, action: AnyAction) => ({
            ...state,
            addedKeywordsIDs: setAddedKeywordIDs(action.payload)
        }),
        [actionTypes.CLEAR_ADDED_KEYWORD_IDS]: (state, action: AnyAction) => ({
            ...state,
            addedKeywordsIDs: []
        }),
        [actionTypes.SAVE_CATEGORY_LIST]: (state, action: AnyAction) => ({
            ...state,
            categoryList: action.payload
        }),
        [actionTypes.SAVE_SITE_URLS_LIST]: (state, action: AnyAction) => ({
            ...state,
            siteURLSList: action.payload
        }),
        [actionTypes.SAVE_CUSTOMER_IDS_LIST]: (state, action: AnyAction) => ({
            ...state,
            customerIdsList: action.payload
        }),
        [actionTypes.CALL_TO_ASSIGN_CATEGORY_PRESENT]: (state, action: AnyAction) => ({
            ...state,
            isCategoryAlreadyPresent: action.payload
        }),
        [actionTypes.SET_ACTIVE_PROJECT_ID]: (state, action: AnyAction) => ({
            ...state,
            projectID: action.payload
        }),
        [actionTypes.SAVE_COMPETITORS_LIST]: (state, action: AnyAction) => ({
            ...state,
            competitorsList: action.payload,
            preSavedCompetitors: setListOfPreSavedCompUrls(action.payload)
        }),
        [actionTypes.SAVE_SUGGESTED_COMPETITOR_DOMAINS]: (state, action: AnyAction) => ({
            ...state,
            suggestedCompetitorDomains: [...action.payload]
        }),
        [actionTypes.SET_SUGGESTED_COMPETITOR_DOMAIN_API_STATUS]: (state, action: AnyAction) => ({
            ...state,
            isSuggestedCompetitorDomainsAPICalled: action.payload
        }),
        [actionTypes.SET_STATUS_CODE_OF_SAVE_COMPETITOR_API]: (state, action: AnyAction) => ({
            ...state,
            statusCodeOfSaveCompetitorAPi: action.payload
        }),
        [actionTypes.SET_IS_KW_RESEARCH_ADD_CATEGORY_SUCCESS]: (state, action: AnyAction) => ({
            ...state,
            isCategoryAddSuccessInKwResearch: action.payload
        }),
        [actionTypes.SET_STATUS_CODE_OF_SUBMIT_SITEURL_API]: (state, action: AnyAction) => ({
            ...state,
            statusCodeOfSubmitSiteurlAPi: action.payload
        }),
        [actionTypes.SET_STATUS_CODE_OF_SUBMIT_CUSTOMER_IDS_API]: (state, action: AnyAction) => ({
            ...state,
            statusCodeOfSubmitCustomerIdsAPi: action.payload
        }),
        [actionTypes.SET_IS_OPTIMIZATION_KEYWORD_SUCCESS]: (state, action: AnyAction) => ({
            ...state,
            isOptimizationKeywordSuccess: action.payload
        }),
        [actionTypes.RESET_KWD_CATEGORY_AND_KWD_OPTIMIZATION_STATUS]: (state, action: AnyAction) => ({
            ...state,
            isOptimizationKeywordSuccess: undefined,
            isCategoryAddSuccessInKwResearch: undefined
        }),
        [actionTypes.SET_IS_UPLOAD_FILE_KEYWORD_SUCCESS]: (state, action: AnyAction) => ({
            ...state,
            fileUploadStatus: action.payload
        }),
        [actionTypes.SET_KEYWORD_UPLOAD_FILE]: (state, action: AnyAction) => ({
            ...state,
            fileUploadMessage: action.payload
        }),
        [actionTypes.SET_STATUS_CODE_OF_CREATE_PROJECT_API]: (state, action: AnyAction) => ({
            ...state,
            statusCodeOfCreateProjectAPI: action.payload
        }),
        [actionTypes.SET_CURRENT_PAGINATION_FOR_KEYWORD_DETAILS]: (state, action: AnyAction) => ({
            ...state,
            displayKeywordDetails: action.payload
        }),
        [actionTypes.RESET_ADD_KEYWORD_STATE_STATUS]: (state, action: AnyAction) => ({
            ...state,
            fileUploadStatus: undefined,
            fileUploadMessage: undefined,
            errorFileUploadStatus: undefined
        }),
        [actionTypes.SET_PROJECT_DEVICE_TYPE]: (state, action: AnyAction) => ({
            ...state,
            isMobile: action.payload
        }),
        [actionTypes.CALL_TO_SAVE_UPLOAD_PROJECT_KEYWORD]: (state, action: AnyAction) => ({
            ...state,
            addKeywordNotifications: updateAddKeywordsNotificationList(state.addKeywordNotifications, action.payload)
        }),
        [actionTypes.SET_ERROR_FILE_STATUS]: (state, action: AnyAction) => ({
            ...state,
            errorFileUploadStatus: action.payload
        }),
        [actionTypes.SET_ERROR_FILE_API_RESPONSE]: (state, action: AnyAction) => ({
            ...state,
            errorFileApiResponse: action.payload
        }),
        [actionTypes.RESET_FILE_UPLOAD_STATUS]: (state, action: AnyAction) => ({
            ...state,
            fileUploadStatus: undefined,
            fileUploadMessage: undefined
        }),
        [actionTypes.SET_PROJECT_CRAWLING_FREQUENCY]: (state, action: AnyAction) => ({
            ...state,
            crawlFreq: action.payload
        }),
        [actionTypes.SET_PROJECT_INITIAL_CRAWLING_FREQUENCY]: (state, action: AnyAction) => ({
            ...state,
            initialCrawlFreq: action.payload
        }),
    },
    INIT_STATE
);

export default Reducer;
