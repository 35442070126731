import { AnyAction } from "redux";
import { put, takeEvery, takeLatest, call, cancelled, delay, cancel, select, take, race } from "redux-saga/effects";
import Store from "../../../../../app/store";
import Apis from "../../../../../app/apis";
import SimulatorApis from "../../../ducks/apis";
import MultiKeywordApis from "./api";
import actionTypes, {
    setKeywordRequestIds,
    callToGetMultiKeywordTrackRequestStatusApi,
    setEnableSimulatePerformance,
    setEnableSimulateScore,
    setKeywordProcessedInfo,
    setThemeKeywordFromProject,
    setIsSubmitted,
    setHideLoader,
    setPartialKeywordFetched,
    reSetPartialKeywordFetched,
    setFileuploadRequestIdMkw,
    isThemeKeywordFromProjectLoading,
    callRelatedKeywordsServiceSSE,
    callRelatedKeywordsServiceNonLiveSSE,
    setRelatedNonLiveKeywords,
    setRelatedKeywordsFetchingURLParam,
    setRelatedKeywordsFetchingViaKeywordParam,
    setRelatedKeywordsNonLiveFetching,
    setSSeRetriedAttemptForURLParam,
    setSSeRetriedAttemptForKeywordParam,
    setSSeNonLiveRetriedAttempt,
    setEnableCompetitorApi,
    callToGetCompetitorsScoreApi,
    setMkwTrackInitiated,
    setIsFileUploaded,
    setIsTrackRequestStart,
    callRelatedKeywordsServiceSSEViaKeywordParam,
    setRelatedKeywordsKwParam,
    setRelatedKeywordsURLParam,
    setPageCrawledStatus
} from "./actions";
import {
    MULTI_KEYWORD_TRACK_TIME_IN_SEC, COMPETITOR_REQUEST_DELAY,
    MULTI_KW_ALLOWED_PERCENTAGE_KW_TO_PROGRESS,
    MULTI_KEYWORD_MAX_TRACKING_TIME_IN_MIN, TRACK_REQUEST_DELAY
} from "./const";
import {
    InitiateRequestErrors,
    HtmlUploadErrors,
    RELATED_KW_SSE_EVENT_TYPES
} from "../../../../../app/const";

import { isSomePercentKeywordProcessed, isAnyOneKwProcessed, markAllKeywordAsProcessed, listenSSEvents } from "./utils";
import { ApplicationStateInterface } from "../../../../../app/duck/types";
import {
    callToResetIsSimulateDone,
    setSimulationsVersionChangeFlag,
    setUrl,
    updateSidToSaveCompetitorsScore,
    setIsContentFetched,
    callToGetCompetitorScoreNewKeyword,
    callToGetModelScore,
    setCompetitorsScore
} from "../../../../simulator/ducks/actions";
import { getContext } from "../../../../../app/duck/context-container";
import {
    getSimulationsVersionChangeFlag,
    getSSeRetriedAttemptsForURLParam,
    getNonLiveSSeRetriedAttempts,
    getSSeRetriedAttemptsForKeywordParam,
    getCompetitorStatus,
    getSelectedKeywords,
    getMultiKeywordLocale,
    getMultiKeywordDomainUrl
} from "./selector";
import { AggregationTypes, CompetitorsScorePayloadInterface } from "../../../../simulator/ducks/types";
import { getSidToSaveCompetitorScore } from "../../../../simulator/ducks/selector";
import { isEmpty, isUndefined } from "lodash";
import { setSerpProgressStatusFromHistoryTable } from "../../../../../app/duck/actions";
import { ApiConfig } from "../../../../../app/config/apiConfig";
import { ApiPath } from "../../../../../app/config/apiPath";
import { MultiKeywordTrackRequestApiResponseInterface } from "./types";

// ** INITIATE REQUEST API SAGA **

// tslint:disable-next-line:typedef
function* callToGetMultiKeywordRequestIds(action: AnyAction) {
    yield put(setIsTrackRequestStart(true));
    yield put(setKeywordProcessedInfo({}));
    yield put(reSetPartialKeywordFetched());
    yield put(callToResetIsSimulateDone());

    const payload = {
        ...action.payload,
        needToHandleError: true,
        isSpecificError: true,
        isBeta: true,
        errorList: InitiateRequestErrors
    };

    if (!action.payload.project_id) {
        delete payload.project_id;
    }
    yield put(setMkwTrackInitiated(true));
    const response = yield call(MultiKeywordApis.getMultiKeywordRequestIds, payload);
    if (response.status === 200 || response.status === 502 || response.status === 504) {
        const store: ApplicationStateInterface = Store.getState();
        if (!isUndefined(store.multiKeywordBeta.fileUpload_requestId)) {
            yield put(setIsContentFetched(false));
        }
        yield put(setKeywordRequestIds(response.data.keywords));
        yield put(updateSidToSaveCompetitorsScore());
        const requestIds = {
            req_id: Object.values(response.data.keywords),
            search_url: [response.data.normalized_url],
            is_url_live: action.payload.is_url_live
        };
        const { notifyErrorMethod } = action.payload;
        const parameter = {
            requestIds,
            timerStart: Date.now(),
            count: 0,
            ...(notifyErrorMethod && { notifyErrorMethod }),
            needToHandleError: true
        };
        const competitorPayload = {
            keywords:
                store.multiKeywordBeta.selectedKeywords.length > 0
                    ? store.multiKeywordBeta.selectedKeywords
                    : action.payload.keyword,
            locale: action.payload.locale,
            search_url: action.payload.search_url.toString(),
            ...(notifyErrorMethod && { notifyErrorMethod }),
            project_id: action.payload.project_id,
            needToHandleError: true
        };

        // calling track request api after getting the request ids
        yield put(setSimulationsVersionChangeFlag(false));
        yield put(callToGetMultiKeywordTrackRequestStatusApi(parameter));
        yield put(setSerpProgressStatusFromHistoryTable());
        yield put(setUrl(response.data.normalized_url));
        yield put(callToGetCompetitorsScoreApi(competitorPayload));
        yield put(setPageCrawledStatus(true));
    } else {
        yield put(setIsTrackRequestStart(false));
        yield put(setIsSubmitted(false));
        yield put(setMkwTrackInitiated(false));
        yield put(setHideLoader());
        yield put(setPageCrawledStatus(false));
    }
}

// ** TRACK REQUEST API SAGA **
// tslint:disable-next-line:typedef
function* callToGetMultiKeywordTrackRequestStatus(action: AnyAction) {
    const { requestIds, needToHandleError, notifyErrorMethod } = action.payload;
    const trackRequestPayload = {
        ...requestIds,
        needToHandleError,
        notifyErrorMethod,
        isBeta: true
    };
    let isSimulationVersionChanged = yield select(getSimulationsVersionChangeFlag);
    const isCompetitorStatus = yield select(getCompetitorStatus);
    if (isSimulationVersionChanged || window.location.pathname !== "/simulation") {
        yield cancel();
    }
    const response = yield call(MultiKeywordApis.getMultiKeywordTrackRequestStatus, trackRequestPayload);
    if (action.payload.count < 3 && (response.status < 400 || response.status >= 500)) {
        isSimulationVersionChanged = yield select(getSimulationsVersionChangeFlag);
        if (!isSimulationVersionChanged) {
            const trackRequestStatus: MultiKeywordTrackRequestApiResponseInterface = response.data;
            const estimatedTime = Date.now() - action.payload.timerStart;
            const estimatedSecondsElapsed = new Date(estimatedTime).getSeconds();
            const estimatedMinuteElapsed = Math.ceil(estimatedTime / 60000);
            if (response.status === 200) {
                const { is_request_completed, are_competitors_processed } = trackRequestStatus;
                yield put(setIsTrackRequestStart(false));
                yield put(setKeywordProcessedInfo(trackRequestStatus.request_ids));
                yield put(setEnableSimulateScore(trackRequestStatus.are_my_urls_processed));
                yield put(setEnableSimulatePerformance(is_request_completed));
                const store: ApplicationStateInterface = Store.getState();
                if (!isCompetitorStatus && are_competitors_processed) {
                    yield delay(TRACK_REQUEST_DELAY);
                    const selectedKeywords = yield select(getSelectedKeywords);
                    const locale = yield select(getMultiKeywordLocale);
                    const url = yield select(getMultiKeywordDomainUrl);
                    const keyword = selectedKeywords;
                    yield put(
                        callToGetModelScore({
                            url,
                            locale: locale ? locale : store.context.selectedLocale,
                            keywords: keyword,
                            aggregation_type: AggregationTypes.WEIGHTED_AVG_SEARCH_VOLUME,
                            are_competitors_processed: true
                        })
                    );
                    yield put(
                        callToGetCompetitorScoreNewKeyword({
                            locale: locale ? locale : store.context.selectedLocale,
                            search_url: url,
                            keywords: keyword
                        })
                    );
                }

                yield put(setEnableCompetitorApi(are_competitors_processed));
                if (
                    !store.multiKeywordBeta.isPartialKeywordReady &&
                    estimatedSecondsElapsed < MULTI_KEYWORD_TRACK_TIME_IN_SEC &&
                    isSomePercentKeywordProcessed(MULTI_KW_ALLOWED_PERCENTAGE_KW_TO_PROGRESS, trackRequestStatus.request_ids)
                ) {
                    yield put(setHideLoader());
                    yield put(setPartialKeywordFetched());
                } else if (
                    !store.multiKeywordBeta.isPartialKeywordReady &&
                    estimatedSecondsElapsed >= MULTI_KEYWORD_TRACK_TIME_IN_SEC &&
                    isAnyOneKwProcessed(trackRequestStatus.request_ids)
                ) {
                    yield put(setHideLoader());
                    yield put(setPartialKeywordFetched());
                }
            }
            if (
                isUndefined(trackRequestStatus) ||
                ((isUndefined(trackRequestStatus.are_my_urls_processed) ||
                    !trackRequestStatus.are_my_urls_processed ||
                    isUndefined(trackRequestStatus.is_request_completed) ||
                    !trackRequestStatus.is_request_completed) &&
                    estimatedMinuteElapsed <= MULTI_KEYWORD_MAX_TRACKING_TIME_IN_MIN)
            ) {
                yield delay(TRACK_REQUEST_DELAY);
                const payload = {
                    ...action.payload,
                    count: response.status !== 200 ? action.payload.count + 1 : 0,
                    needToHandleError: true
                };
                yield put(callToGetMultiKeywordTrackRequestStatusApi(payload));
            } else if (estimatedMinuteElapsed > MULTI_KEYWORD_MAX_TRACKING_TIME_IN_MIN) {
                yield put(
                    setKeywordProcessedInfo(
                        markAllKeywordAsProcessed(!isUndefined(trackRequestStatus.request_ids) ? trackRequestStatus.request_ids : {})
                    )
                );
                yield put(setIsTrackRequestStart(false));
                yield put(setEnableSimulatePerformance(true));
                yield put(setEnableSimulateScore(true));
                yield put(setEnableCompetitorApi(true));
            }
        }
    } else {
        yield put(setIsTrackRequestStart(false));
        yield put(setIsSubmitted(false));
        yield put(setHideLoader());
        yield put(setEnableCompetitorApi(true));
    }
}
// tslint:disable-next-line: typedef
function* callToGetThemeKeywordFromProject(action: AnyAction) {
    yield put(isThemeKeywordFromProjectLoading(true));
    const payload = {
        ...action.payload,
        needToHandleError: true
    };
    const response = yield call(MultiKeywordApis.getThemeKeywordList, payload);

    if (response.status === 200) {
        yield put(
            setThemeKeywordFromProject({
                keywordList: response.data.data.keywords,
                themeList: response.data.data.themes
            })
        );
        yield put(isThemeKeywordFromProjectLoading(false));
    }
}
// tslint:disable-next-line: typedef
export function* fileUploadOnPageElementsMkw(action: AnyAction) {
    const { tenantCode } = getContext();

    const parser = {
        tenantCode: tenantCode as string
    };
    const { formData, notifyErrorMethod } = action.payload;
    const payload = {
        formData,
        isBeta: true
    };
    const optionalArgs = {
        notifyErrorMethod,
        needToHandleError: true,
        isSpecificError: true,
        errorList: HtmlUploadErrors
    };
    const fileUploadResponse = yield call(Apis.fileUploadOnPageElements, payload, parser, optionalArgs);
    if (fileUploadResponse.status === 200) {
        yield put(setFileuploadRequestIdMkw(fileUploadResponse.data.request_id));
        yield put(setIsFileUploaded(true));
    }
}
// tslint:disable-next-line:typedef
function* fetchCompetitorsScoreApi(action: AnyAction) {
    const { tenantCode } = getContext();
    const { keywords, search_url, project_id, notifyErrorMethod } = action.payload;
    const store: ApplicationStateInterface = Store.getState();
    const multiKeywordLocale = yield select(getMultiKeywordLocale);
    const payload: CompetitorsScorePayloadInterface = {
        search_url,
        locale: multiKeywordLocale || store.context.selectedLocale,
        keywords:
            store.multiKeywordBeta.selectedKeywords.length > 0
                ? store.multiKeywordBeta.selectedKeywords
                : keywords,
        // keywords: store.multiKeyword.selectedKeywords.length > 0 ? store.multiKeyword.selectedKeywords : keywords,
        // ...(versionSID && { sid: versionSID }),
        ...(store.simulatorBeta.sidNext && { sid: store.simulatorBeta.sidNext }),
        ...(project_id && { project_id }),
        ...(notifyErrorMethod && { notifyErrorMethod }),
        isBeta: true,
        needToHandleError: true
    };
    const parser = {
        tenantCode: tenantCode as string
    };
    let isSimulationVersionChanged = yield select(getSimulationsVersionChangeFlag);
    if (isSimulationVersionChanged || window.location.pathname !== "/simulation") {
        yield cancel();
    }
    const response = yield call(SimulatorApis.fetchCompetitorsScore, payload, parser);
    if (response.status < 400 || response.status >= 500) {
        yield put(setCompetitorsScore(response.data));
        isSimulationVersionChanged = yield select(getSimulationsVersionChangeFlag);
        if (!isSimulationVersionChanged) {
            const sid = yield select(getSidToSaveCompetitorScore);
            if (!isEmpty(sid)) {
                yield call(SimulatorApis.saveCompetitorScores, { sid, isBeta: true }, parser);
                yield put(updateSidToSaveCompetitorsScore());
            }
            if (!store.multiKeywordBeta.enableCompetitorApi) {
                yield delay(COMPETITOR_REQUEST_DELAY);
                const payloadData = {
                    ...action.payload,
                    needToHandleError: true
                };
                yield put(callToGetCompetitorsScoreApi(payloadData));
            }
        }
    } else {
        yield put(setHideLoader());
    }
}

// Initiate call to get Req ID required for SSE call
// tslint:disable-next-line: typedef
function* callToGetRelatedKeywordRequestIds(action: AnyAction) {
    const payload = {
        ...(action.payload.locale && { locale: action.payload.locale }),
        ...(action.payload.keyword && { keyword: action.payload.keyword }),
        source: action.payload.source,
        needToHandleError: true,
        isSpecificError: true,
        errorList: InitiateRequestErrors,
        url: action.payload.url
    };
    const { keyword, isLiveFlow, url } = action.payload;
    if (isLiveFlow) {
        if (url) {
            yield put(setSSeRetriedAttemptForURLParam(0));
            yield put(setRelatedKeywordsFetchingURLParam(true));
        }
        if (keyword) {
            yield put(setSSeRetriedAttemptForKeywordParam(0));
            yield put(setRelatedKeywordsFetchingViaKeywordParam(true));
        }
    } else {
        yield put(setSSeNonLiveRetriedAttempt(0));
    }
    if (!keyword) {
        delete payload.source;
    }
    const response = yield call(MultiKeywordApis.getRelatedKeywordRequestIds, payload);
    if (response.status === 200) {
        const parameter = {
            requestId: response.data.data.ref_id,
            token: response.data.data.token,
            keyword: action.payload.keyword
        };
        // if (action.payload.url) {
        if (isLiveFlow) {
            if (url) {
                yield put(setSSeRetriedAttemptForURLParam(0));
                yield put(callRelatedKeywordsServiceSSE(parameter)); // Make call to SSE service for live URL with given params
            }
            if (keyword) {
                yield put(setSSeRetriedAttemptForKeywordParam(0));
                yield put(callRelatedKeywordsServiceSSEViaKeywordParam(parameter));
            }
        } else if (action.payload.keyword) {
            yield put(setSSeNonLiveRetriedAttempt(0));
            yield put(callRelatedKeywordsServiceNonLiveSSE(parameter)); // Make call to SSE service for non-live URL with given params
        }
    }
}

// Function to call related Keywords for live URL wiht URL param
// tslint:disable-next-line:typedef
function* callToGetRelatedKeywordsDataFromSSE(action: AnyAction) {
    const { requestId, token } = action.payload;
    const url = `${ApiConfig.rkBaseURL}${ApiPath.RELATED_KEYWORD_URL_SSE_API}?ref_id=${requestId}&token=${token}`;
    const { channel, timeout } = yield race({
        channel: call(listenSSEvents, url),
        timeout: delay(300 * 1000),
        // if user clicks on Fetch Keywords Button in Multikeyword module, this will cancel any ongoing SSE call with URL param
        cancel: take(actionTypes.CANCEL_ON_GOING_SSE_URL_REQUEST)
    });
    const retriedAttempt = yield select(getSSeRetriedAttemptsForURLParam);
    if (retriedAttempt < 3) {
        yield put(setRelatedKeywordsFetchingURLParam(true));
    }
    if (timeout) {
        if (retriedAttempt < 3) {
            const parameter = {
                requestId,
                token
            };
            channel.close();
            yield put(setSSeRetriedAttemptForURLParam(retriedAttempt + 1));
            yield put(callRelatedKeywordsServiceSSE(parameter));
        }
        channel.close();
        yield put(setRelatedKeywordsFetchingURLParam(RELATED_KW_SSE_EVENT_TYPES.req_failed));
    }
    try {
        while (true) {
            if (window.location.pathname !== "/simulation") {
                channel.close();
            }
            try {
                const response = yield take(channel);
                if (response.type === RELATED_KW_SSE_EVENT_TYPES.receive_related_kwds) {
                    if (!isEmpty(response.data)) {
                        yield put(setRelatedKeywordsFetchingURLParam(RELATED_KW_SSE_EVENT_TYPES.receive_related_kwds)); // changed from false
                        yield put(setRelatedKeywordsURLParam(JSON.parse(response.data)));
                    }
                }
                if (response.type === RELATED_KW_SSE_EVENT_TYPES.req_completed) {
                    channel.close();
                    yield put(setRelatedKeywordsFetchingURLParam(RELATED_KW_SSE_EVENT_TYPES.req_completed));
                }
                if (response.type === RELATED_KW_SSE_EVENT_TYPES.req_failed || response.type === RELATED_KW_SSE_EVENT_TYPES.error) {
                    if (retriedAttempt < 3) {
                        const parameter = {
                            requestId,
                            token
                        };
                        channel.close();
                        yield put(setSSeRetriedAttemptForURLParam(retriedAttempt + 1));
                        yield put(callRelatedKeywordsServiceSSE(parameter));
                    }
                    channel.close();
                    yield put(setRelatedKeywordsFetchingURLParam(RELATED_KW_SSE_EVENT_TYPES.req_failed));
                }
            } catch (error) {
                if (retriedAttempt < 3) {
                    const parameter = {
                        requestId,
                        token
                    };
                    channel.close();
                    yield put(setSSeRetriedAttemptForURLParam(retriedAttempt + 1));
                    yield put(callRelatedKeywordsServiceSSE(parameter));
                }
                channel.close();
                yield put(setRelatedKeywordsFetchingURLParam(RELATED_KW_SSE_EVENT_TYPES.req_failed));
            }
        }
    } finally {
        if (yield cancelled()) {
            channel.close();
        }
    }
}

// Function to call related Keywords for live URL with Keyword Param
// tslint:disable-next-line:typedef
function* callToGetRelatedKeywordsDataFromSSEViaKeywordParam(action: AnyAction) {
    const { requestId, token } = action.payload;
    const url = `${ApiConfig.rkBaseURL}${ApiPath.RELATED_KEYWORD_SSE_API}?ref_id=${requestId}&token=${token}`;
    const { channel, timeout } = yield race({
        channel: call(listenSSEvents, url),
        timeout: delay(300 * 1000)
    });
    const retriedAttempt = yield select(getSSeRetriedAttemptsForKeywordParam);
    if (retriedAttempt < 3) {
        yield put(setRelatedKeywordsFetchingViaKeywordParam(true));
    }
    if (timeout) {
        if (retriedAttempt < 3) {
            const parameter = {
                requestId,
                token
            };
            channel.close();
            yield put(setSSeRetriedAttemptForKeywordParam(retriedAttempt + 1));
            yield put(callRelatedKeywordsServiceSSEViaKeywordParam(parameter));
        }
        channel.close();
        yield put(setRelatedKeywordsFetchingViaKeywordParam(RELATED_KW_SSE_EVENT_TYPES.req_failed));
    }
    try {
        while (true) {
            if (window.location.pathname !== "/simulation") {
                channel.close();
            }
            try {
                const response = yield take(channel);
                if (response.type === RELATED_KW_SSE_EVENT_TYPES.receive_related_kwds) {
                    if (!isEmpty(response.data)) {
                        yield put(setRelatedKeywordsFetchingViaKeywordParam(RELATED_KW_SSE_EVENT_TYPES.receive_related_kwds)); // changed from false
                        yield put(setRelatedKeywordsKwParam(JSON.parse(response.data)));
                    }
                }
                if (response.type === RELATED_KW_SSE_EVENT_TYPES.req_completed) {
                    channel.close();
                    yield put(setRelatedKeywordsFetchingViaKeywordParam(RELATED_KW_SSE_EVENT_TYPES.req_completed));
                }
                if (response.type === RELATED_KW_SSE_EVENT_TYPES.req_failed || response.type === RELATED_KW_SSE_EVENT_TYPES.error) {
                    if (retriedAttempt < 3) {
                        const parameter = {
                            requestId,
                            token
                        };
                        channel.close();
                        yield put(setSSeRetriedAttemptForKeywordParam(retriedAttempt + 1));
                        yield put(callRelatedKeywordsServiceSSEViaKeywordParam(parameter));
                    }
                    channel.close();
                    yield put(setRelatedKeywordsFetchingViaKeywordParam(RELATED_KW_SSE_EVENT_TYPES.req_failed));
                }
            } catch (error) {
                if (retriedAttempt < 3) {
                    const parameter = {
                        requestId,
                        token
                    };
                    channel.close();
                    yield put(setSSeRetriedAttemptForKeywordParam(retriedAttempt + 1));
                    yield put(callRelatedKeywordsServiceSSEViaKeywordParam(parameter));
                }
                channel.close();
                yield put(setRelatedKeywordsFetchingViaKeywordParam(RELATED_KW_SSE_EVENT_TYPES.req_failed));
            }
        }
    } finally {
        if (yield cancelled()) {
            channel.close();
        }
    }
}

// Function to call Related keywords for non-live URL
// tslint:disable-next-line:typedef
function* callToGetRelatedKeywordsDataNonLiveFromSSE(action: AnyAction) {
    const { requestId, token } = action.payload;
    const url = `${ApiConfig.rkBaseURL}${ApiPath.RELATED_KEYWORD_SSE_API}?ref_id=${requestId}&token=${token}`;
    const { channel, timeout } = yield race({
        channel: call(listenSSEvents, url),
        timeout: delay(300 * 1000)
    });
    const retriedAttempt = yield select(getNonLiveSSeRetriedAttempts); // change to non-live
    if (retriedAttempt < 3) {
        yield put(setRelatedKeywordsNonLiveFetching(true));
    }
    if (timeout) {
        if (retriedAttempt < 3) {
            const parameter = {
                requestId,
                token
            };
            channel.close();
            yield put(setSSeNonLiveRetriedAttempt(retriedAttempt + 1));
            yield put(callRelatedKeywordsServiceNonLiveSSE(parameter));
        }
        channel.close();
        yield put(setRelatedNonLiveKeywords());
        yield put(setRelatedKeywordsNonLiveFetching(RELATED_KW_SSE_EVENT_TYPES.req_failed));
    }
    try {
        while (true) {
            if (window.location.pathname !== "/simulation") {
                channel.close();
            }
            try {
                const response = yield take(channel);
                if (response.type === RELATED_KW_SSE_EVENT_TYPES.receive_related_kwds) {
                    if (!isEmpty(response.data)) {
                        yield put(setRelatedKeywordsNonLiveFetching(false));
                        yield put(setRelatedNonLiveKeywords(JSON.parse(response.data)));
                    }
                }
                if (response.type === RELATED_KW_SSE_EVENT_TYPES.req_completed) {
                    channel.close();
                    yield put(setRelatedKeywordsNonLiveFetching(RELATED_KW_SSE_EVENT_TYPES.req_completed));
                }
                if (response.type === RELATED_KW_SSE_EVENT_TYPES.req_failed || response.type === RELATED_KW_SSE_EVENT_TYPES.error) {
                    if (retriedAttempt < 3) {
                        const parameter = {
                            requestId,
                            token
                        };
                        channel.close();
                        yield put(setSSeNonLiveRetriedAttempt(retriedAttempt + 1));
                        yield put(callRelatedKeywordsServiceNonLiveSSE(parameter));
                    }
                    channel.close();
                    yield put(setRelatedNonLiveKeywords());
                    yield put(setRelatedKeywordsNonLiveFetching(RELATED_KW_SSE_EVENT_TYPES.req_failed));
                }
            } catch (error) {
                if (retriedAttempt < 3) {
                    const parameter = {
                        requestId,
                        token
                    };
                    channel.close();
                    yield put(setSSeNonLiveRetriedAttempt(retriedAttempt + 1));
                    yield put(callRelatedKeywordsServiceNonLiveSSE(parameter));
                }
                channel.close();
                yield put(setRelatedNonLiveKeywords());
                yield put(setRelatedKeywordsNonLiveFetching(RELATED_KW_SSE_EVENT_TYPES.req_failed));
            }
        }
    } finally {
        if (yield cancelled()) {
            channel.close();
        }
    }
}

// tslint:disable-next-line: typedef
export function* watchForCallToGetThemeKeywordFromProject() {
    yield takeEvery(actionTypes.CALL_TO_GET_THEME_KEYWORD_FROM_PROJECT, callToGetThemeKeywordFromProject);
}
// tslint:disable-next-line:typedef
export function* watchForCallToGetMultiKeywordTrackRequestStatus() {
    yield takeLatest(actionTypes.CALL_TO_GET_MULTI_KEYWORD_TRACK_REQUEST_API, callToGetMultiKeywordTrackRequestStatus);
}

// tslint:disable-next-line:typedef
export function* watchForCallToGetMultiKeywordRequestIds() {
    yield takeEvery(actionTypes.CALL_TO_GET_MULTI_KEYWORD_REQUEST_IDS_API, callToGetMultiKeywordRequestIds);
}

// tslint:disable-next-line:typedef
export function* watchForCallFileUploadMkw() {
    yield takeEvery(actionTypes.CALL_FILE_UPLOAD_MKW, fileUploadOnPageElementsMkw);
}

// tslint:disable-next-line:typedef
export function* watchForCallToGetRelatedKeywordRequestIds() {
    yield takeEvery(actionTypes.CALL_TO_GET_RELATED_KEYWORD_REQUEST_IDS_API, callToGetRelatedKeywordRequestIds); // initiate call for SSE
}

// tslint:disable-next-line:typedef
export function* watchForCallToGetRelatedKeywordTrackRequestStatus() {
    // SSE call to get Related keywords data for live URL via URL param
    yield takeEvery(actionTypes.CALL_RELATED_KEYWORDS_SERVICE, callToGetRelatedKeywordsDataFromSSE);
}

// tslint:disable-next-line:typedef
export function* watchForCallToGetRelatedKeywordTrackRequestStatusViaKeywordParam() {
    // SSE call to get Related keywords data for live URL via Keyword param
    yield takeEvery(actionTypes.CALL_RELATED_KEYWORDS_SERVICE_VIA_KEYWORD, callToGetRelatedKeywordsDataFromSSEViaKeywordParam);
}

// tslint:disable-next-line:typedef
export function* watchForCallToGetRelatedKeywordNonLiveTrackRequestStatus() {
    // SSE call to get Related keywords data for non-live URL
    yield takeLatest(actionTypes.CALL_RELATED_KEYWORDS_SERVICE_FOR_NON_LIVE, callToGetRelatedKeywordsDataNonLiveFromSSE);
}

// tslint:disable-next-line:typedef
export function* watchForCallFetchCompetitorsScoreApi() {
    yield takeLatest(actionTypes.CALL_TO_GET_COMPETITORS_SCORE_API, fetchCompetitorsScoreApi);
}
