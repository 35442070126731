import * as React from "react";
import { RoutesInterface, RouteType } from "./types";
import {
    HOME_URL,
    AUTO_LOGIN_URL,
    KEYWORD_ANALYSIS_URL,
    PAGE_OPTIMIZER_URL,
    KEYWORD_OPTIMIZATION_THEME_URL,
    KEYWORD_OPTIMIZATION_URL,
    COMPETITIVE_RESEARCH_URL,
    CR_DATA_ON_DEMAND_URL,
    PR_GSC_KEYWORD_DETAILS_URL,
    PR_GSC_PAGE_ANALYSIS_URL,
    PR_GSC_URL,
    CR_SHARE_OF_VOICE_CATEGORY_URL,
    CR_SHARE_OF_VOICE_OVERVIEW_URL,
    CR_SHARE_OF_VOICE_URL,
    CR_TOPICAL_AUTHORITY_CATEGORY_URL,
    CR_TOPICAL_AUTHORITY_DOMAIN_URL,
    CR_TOPICAL_AUTHORITY_URL,
    SERP_RESULT_URL,
    SIMULATOR_URL,
    PROJECT_SETUP_DOMAIN_DETAILS_URL,
    KEYWORD_RESEARCH_HOME_URL,
    KEYWORD_RESEARCH_TOOL_URL,
    CONTENT_BRIEF,
    CONTENT_BRIEF_NEW,
    CONTENT_BRIEF_VIEW,
    CR_ALPS_SCORE_ANALYSIS_URL,
    CR_ALPS_SCORE_ANALYSIS_SCORE_TRENDS_URL,
    PRS_RANK_TRACKING_KEYWORD_RANK_URL,
    PRS_RANK_TRACKING_PAGES_REPORT_URL,
    PRS_PERFORMANCE_REPORTS_URL,
    PRS_RANK_TRACKING_URL,
    CR_ALPS_SCORE_ANALYSIS_GAP_ANALYSIS_URL,
    PROJECT_SETUP_COMPETITORS_URL,
    PROJECT_SETUP_INTEGRATIONS_URL,
    PROJECT_SETUP_ADD_KEYWORDS_URL,
    PROJECT_SETUP_MANAGE_KEYWORDS_URL,
    PROJECT_SETUP_WEBMASTER_CALLBACK_URL,
    PROJECT_SETUP_GOOGLE_ADS_CALLBACK_URL,
    PRS_PROJECT_DASHBOARD_URL,
    PRS_PROJECT_DASHBOARD_PERFORMANCE_DASHBOARD_URL,
    PRS_PERFORMANCE_IMPACT_URL,
    PR_GSC_MASTERVIEW,
    PR_GSC_KEYWORD_URL,
    PR_GSC_KEYWORD_ONLY,
    PR_GSC_URL_ONLY,
    CUSTOM_REPORTING_URL,
    CUSTOM_REPORTING_EDIT_URL,
    CUSTOM_REPORTING_VIEW_URL,
    PRS_PERFORMANCE_IMPACT_REPORT_URL,
    USER_LOGIN,
    USER_LOGIN_HOME,
    CHANGE_PASSWORD,
    USER_LOGOUT_URL,
    CHANGE_ACCOUNT,
    SSO_CALLBACK,
    USER_FORGOT_PASSWORD,
    USER_RESET_PASSWORD,
    SERP_FEATURE_COVERAGE_URL
} from "./routes-url-constant";
import { getFeatureAddons } from "../../app/duck/utils";

const HomeBeta = React.lazy(() => import("../../beta/home"));

const SerpResultBeta = React.lazy(() => import("../../beta/serp-result"));

// @ts-ignore
const KeywordOptimization = React.lazy(() => import("../../DEPRICATED__App/module/keyword-optimization/KeywordOptimization"));
const KeywordAnalysisBeta = React.lazy(() => import("../../beta/key-word-analysis/key-word-analysis"));

// const Simulator = React.lazy(() => import("../../simulator"));
const SimulatorBeta = React.lazy(() => import("../../beta/simulator"));
const DataOnDemand = React.lazy(() => import("../../data-on-demand"));
const PerformanceImpact = React.lazy(() => import("../../performance-impact"));
const SerpDashboard = React.lazy(() => import("../../serp-dashboard"));
const LoginPage = React.lazy(() => import("../../user_account/components/Login/index"));

const CompetitionIntelligence = React.lazy(() => import("../../competitive-intelligence"));

const CustomReporting = React.lazy(() => import("../../custom-reporting"));

const PageOptimizerBeta = React.lazy(() => import("../../beta/page-optimizer"));

const ProjectSetup = React.lazy(() => import("../../project-setup/index"));

const WebmasterCallback = React.lazy(() => import("../../project-setup/components/webmasterCallback"));
const GoogleAdsCallback = React.lazy(() => import("../../project-setup/components/googleAdsCallback"));

const KwResearch = React.lazy(() => import("../../kw-research"));

const KwResearchTool = React.lazy(() => import("../../kw-research/components/keyword-research"));

// @ts-ignore
const FourOhFour = React.lazy(() => import("../../user_account/components/PageNotFound/index"));
const ContentBrief = React.lazy(() => import("../../content-brief"));
const ContentBriefNew = React.lazy(() => import("../../content-brief-new"));
const ContentBriefView = React.lazy(() => import("../../content-brief-view"));

const AutoLogin = React.lazy(() => import("../../auto-login"));

const EmailVerification = React.lazy(() => import("../../email-verification/index"));

const ChangeUserTenant = React.lazy(() => import("../../user_account/components/changeTenant/index"));
const LogoutComponent = React.lazy(() => import("../../user_account/components/Logout/index"));
const SSOCallbackComponent = React.lazy(() => import("../../user_account/components/SsoCallback/index"));
const ChangeUserPassword = React.lazy(() => import("../../user_account/components/ChangePassword/index"));
const ForgotUserPassword = React.lazy(() => import("../../user_account/components/ForgotPassword/index"));
const ResetUserPassword = React.lazy(() => import("../../user_account/components/ResetPassword/index"));


export const standaloneRoutes: RoutesInterface[] = [
    {
        id: "home",
        from: "/",
        exact: true,
        to: PAGE_OPTIMIZER_URL,
        type: RouteType.REDIRECT
    },
    {
        id: "page-optimizer",
        to: PAGE_OPTIMIZER_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: PageOptimizerBeta
    },
    {
        id: "simulation",
        to: SIMULATOR_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: SimulatorBeta,
    },
    {
        id: "page-not-found",
        to: "*",
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: FourOhFour
    }
];

const featureAddOns = getFeatureAddons();

const CUSTOM_GSC_ROUTES = featureAddOns.includes("custom-gsc") ? [
    {
        id: "gsc-masterview",
        to: PR_GSC_MASTERVIEW,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: CompetitionIntelligence
    },
    {
        id: "gsc-keyword-url",
        to: PR_GSC_KEYWORD_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: CompetitionIntelligence
    },
    {
        id: "gsc-keyword-only",
        to: PR_GSC_KEYWORD_ONLY,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: CompetitionIntelligence
    },
    {
        id: "gsc-url-only",
        to: PR_GSC_URL_ONLY,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: CompetitionIntelligence
    }
] : [];

// @ts-ignore
const ROUTES: RoutesInterface[] = [
    {
        id: "home",
        to: HOME_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: HomeBeta
    },

    {
        id: "serp-result",
        to: SERP_RESULT_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: SerpResultBeta
    },
    {
        id: "theme-keyword-optimization",
        to: KEYWORD_OPTIMIZATION_THEME_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: KeywordOptimization
    },
    {
        id: "keyword-optimization",
        to: KEYWORD_OPTIMIZATION_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: KeywordOptimization
    },
    {
        id: "keyword-analysis",
        to: KEYWORD_ANALYSIS_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: KeywordAnalysisBeta
    },
    {
        id: "page-optimizer",
        to: PAGE_OPTIMIZER_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: PageOptimizerBeta
    },
    {
        id: "simulation",
        to: SIMULATOR_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: SimulatorBeta
    },
    {
        id: "project-setup",
        to: PROJECT_SETUP_DOMAIN_DETAILS_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: ProjectSetup
    },
    {
        id: "project-setup",
        to: PROJECT_SETUP_COMPETITORS_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: ProjectSetup
    },
    {
        id: "project-setup",
        to: PROJECT_SETUP_INTEGRATIONS_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: ProjectSetup
    },
    {
        id: "project-setup-webmaster-callback",
        to: PROJECT_SETUP_WEBMASTER_CALLBACK_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: WebmasterCallback
    },
    {
        id: "project-setup-googleads-callback",
        to: PROJECT_SETUP_GOOGLE_ADS_CALLBACK_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: GoogleAdsCallback
    },
    {
        id: "project-setup",
        to: PROJECT_SETUP_ADD_KEYWORDS_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: ProjectSetup
    },
    {
        id: "project-setup",
        to: PROJECT_SETUP_MANAGE_KEYWORDS_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: ProjectSetup
    },
    {
        id: "auto-login",
        to: AUTO_LOGIN_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: AutoLogin
    },
    {
        id: "content-brief",
        to: CONTENT_BRIEF,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: ContentBrief
    },
    {
        id: "content-brief-new",
        to: CONTENT_BRIEF_NEW,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: ContentBriefNew
    },
    {
        id: "content-brief-view",
        to: CONTENT_BRIEF_VIEW,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: ContentBriefView
    },
    // {
    //     id: "competitive-intelligence",
    //     from: COMPETITIVE_INTELLIGENCE_URL,
    //     exact: true,
    //     to: CI_SHARE_OF_VOICE_URL,
    //     type: RouteType.REDIRECT,
    // },
    // {
    //     id: "ci-share-of-voice",
    //     to: CI_SHARE_OF_VOICE_URL,
    //     exact: true,
    //     type: RouteType.ROUTE,
    //     // @ts-ignore
    //     Component: CompetitionIntelligence,
    // },
    // Performance Reports
    {
        id: "performance-reports",
        from: PRS_PERFORMANCE_REPORTS_URL,
        exact: true,
        to: PRS_RANK_TRACKING_PAGES_REPORT_URL,
        type: RouteType.REDIRECT
    },
    {
        id: "prs-project-dashboard",
        from: PRS_PROJECT_DASHBOARD_URL,
        exact: true,
        to: PRS_PROJECT_DASHBOARD_PERFORMANCE_DASHBOARD_URL,
        type: RouteType.REDIRECT
    },
    {
        id: "pprs-project-dashboard-performance-dashboard",
        to: PRS_PROJECT_DASHBOARD_PERFORMANCE_DASHBOARD_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: CompetitionIntelligence
    },
    {
        id: "prs-rank-tracking",
        from: PRS_RANK_TRACKING_URL,
        exact: true,
        to: PRS_RANK_TRACKING_PAGES_REPORT_URL,
        type: RouteType.REDIRECT
    },
    {
        id: "prs-rank-tracking-keyword-rank-report",
        to: PRS_RANK_TRACKING_KEYWORD_RANK_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: CompetitionIntelligence
    },
    {
        id: "prs-rank-tracking-keyword-page-report",
        to: PRS_RANK_TRACKING_PAGES_REPORT_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: CompetitionIntelligence
    },
    {
        id: "gsc",
        from: PR_GSC_URL,
        exact: true,
        to: PR_GSC_PAGE_ANALYSIS_URL,
        type: RouteType.REDIRECT
    },
    {
        id: "gsc-page-analysis",
        to: PR_GSC_PAGE_ANALYSIS_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: CompetitionIntelligence
    },
    {
        id: "gsc-keyword-details",
        to: PR_GSC_KEYWORD_DETAILS_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: CompetitionIntelligence
    },
    {
        id: "prs-performance-impact",
        to: PRS_PERFORMANCE_IMPACT_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: PerformanceImpact
    },
    {
        id: "prs-performance-impact-report",
        to: PRS_PERFORMANCE_IMPACT_REPORT_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: PerformanceImpact
    },
    {
        id: "nav-serp-feature-coverage",
        to: SERP_FEATURE_COVERAGE_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: SerpDashboard
    },
    {
        id: "login-page",
        to: USER_LOGIN,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: LoginPage
    },
    {
        id: "login-page-home",
        to: USER_LOGIN_HOME,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: LoginPage
    },
    {
        id: "cr-alps-score-analysis",
        from: CR_ALPS_SCORE_ANALYSIS_URL,
        exact: true,
        to: CR_ALPS_SCORE_ANALYSIS_GAP_ANALYSIS_URL,
        type: RouteType.REDIRECT
    },
    {
        id: "cr-alps-score-analysis-score-trends",
        to: CR_ALPS_SCORE_ANALYSIS_SCORE_TRENDS_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: CompetitionIntelligence
    },
    {
        id: "cr-alps-score-analysis-gap-analysis",
        to: CR_ALPS_SCORE_ANALYSIS_GAP_ANALYSIS_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: CompetitionIntelligence
    },
    // Planning and Research
    {
        id: "competitive-research",
        from: COMPETITIVE_RESEARCH_URL,
        exact: true,
        to: CR_SHARE_OF_VOICE_OVERVIEW_URL,
        type: RouteType.REDIRECT
    },
    {
        id: "share-of-voice",
        from: CR_SHARE_OF_VOICE_URL,
        exact: true,
        to: CR_SHARE_OF_VOICE_OVERVIEW_URL,
        type: RouteType.REDIRECT
    },
    {
        id: "cr-share-of-voice-overview",
        to: CR_SHARE_OF_VOICE_OVERVIEW_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: CompetitionIntelligence
    },
    {
        id: "cr-share-of-voice-category",
        to: CR_SHARE_OF_VOICE_CATEGORY_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: CompetitionIntelligence
    },
    {
        id: "cr-topical-authority-redirect",
        from: CR_TOPICAL_AUTHORITY_URL,
        exact: true,
        to: CR_TOPICAL_AUTHORITY_DOMAIN_URL,
        type: RouteType.REDIRECT
    },
    {
        id: "cr-topical-authority-category",
        to: CR_TOPICAL_AUTHORITY_CATEGORY_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: CompetitionIntelligence
    },
    {
        id: "cr-topical-authority-domain",
        to: CR_TOPICAL_AUTHORITY_DOMAIN_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: CompetitionIntelligence
    },
    {
        id: "kwResearch-home",
        to: KEYWORD_RESEARCH_HOME_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: KwResearch
    },
    {
        id: "kwResearch-tool",
        to: KEYWORD_RESEARCH_TOOL_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: KwResearchTool
    },
    {
        id: "data-on-demand",
        to: CR_DATA_ON_DEMAND_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: DataOnDemand
    },
    {
        id: "email-verification",
        to: "/verify/user/:id",
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: EmailVerification,
    },
    // @ts-ignore
    ...CUSTOM_GSC_ROUTES,
    // CUSTOM REPORTING
    // @ts-ignore
    {
        id: "custom-reporting",
        from: CUSTOM_REPORTING_URL,
        exact: true,
        to: CUSTOM_REPORTING_VIEW_URL,
        type: RouteType.REDIRECT
    },
    {
        id: "custom-reporting-edit",
        to: CUSTOM_REPORTING_EDIT_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: CustomReporting
    },
    {
        id: "custom-reporting-view",
        to: CUSTOM_REPORTING_VIEW_URL,
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: CustomReporting
    },
    {
        id: "change-account",
        to: CHANGE_ACCOUNT,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: ChangeUserTenant
    },
    {
        id: "change-password",
        to: CHANGE_PASSWORD,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: ChangeUserPassword
    },
    {
        id: "user-logout",
        to: USER_LOGOUT_URL,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: LogoutComponent
    },
    {
        id: "sso-callback",
        to: SSO_CALLBACK,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: SSOCallbackComponent
    },
    {
        id: "forgot-password",
        to: USER_FORGOT_PASSWORD,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: ForgotUserPassword
    },
    {
        id: "reset-password",
        to: USER_RESET_PASSWORD,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: ResetUserPassword
    },
    {
        id: "page-not-found",
        to: "*",
        exact: true,
        type: RouteType.ROUTE,
        // @ts-ignore
        Component: FourOhFour
    }
];

export default ROUTES;
