import ServiceRequest from "../../app/services";
import { ApiPath, METHOD } from "../../app/config/apiPath";
import { ParserInterface } from "../../app/duck/types";
import { ApiConfig } from "../../app/config/apiConfig";

class Apis {
    public * getCustomerIdsList(
        payload: { projectID: string },
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            method: METHOD.GET,
            url: ApiPath.GET_CUSTOMER_IDS_LIST.replace("{{tenant_code}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.projectID.toString()
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public * unlinkGoogleAdsAccount(
        payload: { projectID: string },
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.DELETE,
            data: payload,
            url: ApiPath.AUTH_GOOGLE_ADS_URL.replace("{{tenant_code}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.projectID.toString()
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }

    public * submitCustomerIds(
        payload: {
            projectID: string;
            customer_id: string,
            mcc_account_id: string,
            customer_name: string,
            mcc_account_name: string
        },
        parser: ParserInterface
        // tslint:disable-next-line:no-any
    ): any {
        const payloadObj = {
            customer_id: payload.customer_id,
            mcc_account_id: payload.mcc_account_id,
            customer_name: payload.customer_name,
            mcc_account_name: payload.mcc_account_name
        };
        const axiosParam = {
            baseURL: ApiConfig.baseURL,
            method: METHOD.POST,
            data: payloadObj,
            url: ApiPath.GET_CUSTOMER_IDS_LIST.replace("{{tenant_code}}", parser.tenantCode).replace(
                "{{project_id}}",
                payload.projectID.toString()
            )
        };
        const response = yield ServiceRequest.invoke(axiosParam);
        return response;
    }
}

export default new Apis();
