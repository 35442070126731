import { all } from "redux-saga/effects";
import {
    watchForGetKeywordUrl as watchForGetKeywordUrlBeta,
    watchForViewAllTopCompatitor as watchForViewAllTopCompatitorBeta,
    watchForDownloadSelect as watchForDownloadSelectBeta,
    watchForDownloadUrlReport as watchForDownloadUrlReportBeta,
    watchForGetParameterDetail as watchForGetParameterDetailBeta,
    watchForGetUrlScore as watchForGetUrlScoreBeta
} from "../../beta/key-word-analysis/ducks/saga";
import { watchForUserDetailsRequest, watchForSubscriptionRequest } from "../../user-subscription/ducks/saga";
import {
    watchForInitialGeneratedContentOutlineCall,
    watchForReGeneratedContentOutlineCall,
    watchGetsaveSimulationHistory as watchGetsaveSimulationHistoryBeta
} from "../../beta/page-optimizer/ducks/saga";
import { watchCallSetContext, watchForCallToGetRequestIdApp, watchForGetTrackRequestCall } from "./saga";
import { watchForGetSerpDataCall as watchForGetSerpDataCallBeta } from "../../beta/serp-result/ducks/saga";
import {
    watchCallToClearUserSelection as watchCallToClearUserSelectionBeta,
    watchForGetProjectDetails as watchForGetProjectDetailsBeta,
    watchForGetTenantLimits as watchForGetTenantLimitsBeta,
    watchForCallFileUpload as watchForCallFileUploadBeta,
    watchFetchAllProjectsList,
    watchForDeleteProject
} from "../../beta/home/ducks/saga";
import {
    watchForCallToGetMultiKeywordRequestIds as watchForCallToGetMultiKeywordRequestIdsBeta,
    watchForCallToGetMultiKeywordTrackRequestStatus as watchForCallToGetMultiKeywordTrackRequestStatusBeta,
    watchForCallToGetThemeKeywordFromProject as watchForCallToGetThemeKeywordFromProjectBeta,
    watchForCallFileUploadMkw as watchForCallFileUploadMkwBeta,
    watchForCallToGetRelatedKeywordRequestIds as watchForCallToGetRelatedKeywordRequestIdsBeta,
    watchForCallToGetRelatedKeywordTrackRequestStatus as watchForCallToGetRelatedKeywordTrackRequestStatusBeta,
    watchForCallToGetRelatedKeywordTrackRequestStatusViaKeywordParam,
    watchForCallToGetRelatedKeywordNonLiveTrackRequestStatus as watchForCallToGetRelatedKeywordNonLiveTrackRequestStatusBeta,
    watchForCallFetchCompetitorsScoreApi as watchForCallFetchCompetitorsScoreApiBeta
} from "../../beta/simulator/Components/AddModifyKeyword/ducks/saga";
import {
    watchForModelScoreCall as watchForModelScoreCallBeta,
    watchForKeywordStuffingCall as watchForKeywordStuffingCallBeta,
    watchForModelScoreDetailCall as watchForModelScoreDetailCallBeta,
    watchForPerformanceScoreCall as watchForPerformanceScoreCallBeta,
    watchForOnPageElementsCall as watchForOnPageElementsCallBeta,
    watchforPostSimulationCall as watchforPostSimulationCallBeta,
    watchForCompetitorsScoreCall as watchForCompetitorsScoreCallBeta,
    watchforPreSimKeywordLevelDetails as watchforPreSimKeywordLevelDetailsBeta,
    watchForVersionListCall as watchForVersionListCallBeta,
    watchForScoresBasedOnVersionCall as watchForScoresBasedOnVersionCallBeta,
    watchForSnapShotMetaInformation as watchForSnapShotMetaInformationBeta,
    watchForContentBasedOnVersionCall as watchForContentBasedOnVersionCallBeta,
    watchForUpdateDescription as watchForUpdateDescriptionBeta,
    watchForSimulationMetaInfo as watchForSimulationMetaInfoBeta,
    watchForCompetitorScoresBasedOnVersionCall as watchForCompetitorScoresBasedOnVersionCallBeta,
    watchForSimulationVersionStatus as watchForSimulationVersionStatusBeta,
    watchGetSimulationDownload,
    watchGetSimulationContentOnlyDownload,
    watchPageAssessmentDownload,
    watchGetCompetitorScoreSSEReqId,
    watchGetCompetitorScoreSSE,
    watchGetCompetitiorScoreNewKeywordReqId,
    watchGetRecomBulbSubHeaderScores,
    watchGetSimulationContentOnlyInitiateDownload,
    watchGetSimulationContentOnlyTrackDownload
} from "../../beta/simulator/ducks/saga";
import {
    watchFetchProjectList,
    watchFetchReportToken,
    watchfetchProjectsList,
    watchFetchTargetDomainFilterDetails,
    watchFetchDefaultProjectTADomain,
    watchFetchSOVTargetCompetitorDomains,
    watchCallRefreshProjectCache,
    watchFetchDefaultProjectCategory
} from "../../competitive-intelligence/ducks/saga";
import {
    watchForCallToGetRelatedKeywordTrackRequestStatus as watchForCallToGetKwResearchRelatedKeywordTrackRequestStatus,
    watchForKwResearchGetRequestId
} from "../../kw-research/ducks/saga";
import { watchGetContentBriefData } from "../../content-brief/ducks/saga";
import {
    watchForAddContentBriefKeywords,
    watchForOnGetContentBriefProgress,
    watchForOnUpdateContentBrief
} from "../../content-brief-new/ducks/saga";
import {
    watchForUpdateCBNameAndDescription,
    watchForGetContentBriefSummary,
    watchForGetContentBriefTabHistory,
    watchForGetContentBriefCompetitors,
    watchForPhrasesToUse
} from "../../content-brief-view/ducks/saga";
import { watchCallToGetRequestIdOnSubmit } from "../../data-on-demand/ducks/saga";
import { watchForgetSuggestedRelatedKW, watchForgetSuggestedRelatedKWReqIds } from "../../suggested-keywords/ducks/saga";
import { watchGetLoginDetails, watchGetTenant } from "../../auto-login/ducks/saga";
import {
    watchCreateProjectAPICall,
    watchUpdateProjectDetailsAPICall,
    watchGetProjectDetailsAPICall,
    watchAddCategoryAPICall,
    watchDeleteCategoryAPICall,
    watchEditCategoryAPICall,
    watchGetCategoryListAPICall,
    watchCallToSaveProjectKeywords,
    watchGetKeywordDetailsAPICall,
    watchGetSiteURLSListAPICall,
    watchGetCustomerIdsListAPICall,
    watchUnlinkGoogleAdsAccountAPICall,
    watchSubmitCustomerIdsAPICall,
    watchUnlinkAccountAPICall,
    watchSubmitSiteURLAPICall,
    watchMapKeywordAndCategories,
    watchAddToOptimizationKeywords,
    watchAssignNewCategoriesAPICall,
    watchUpdateKeywordDetailsAPICall,
    watchDeleteKeywordsFromProject,
    watchGetCompetitorsListAPICall,
    watchPostCompetitorsDomainsAPICall,
    watchDeleteCompetitorsDomainsAPICall,
    watchGetSuggestedCompetitorDomains,
    watchcallToSaveUploadProjectKeyword
} from "../../project-setup/ducks/saga";
import { watchForFetchPhraseContent } from "../../beta/simulator/Components/ContentOptimization/SummaryComponent/ducks/saga";
import { watchForUserVerification, watchForReverifyEmail } from "../../email-verification/ducks/saga";
import { watchFetchTableauEmbedToken } from "../../custom-reporting/ducks/saga";
import { watchForAuthTechParamsBasedOnVersionCall as watchForAuthTechParamsBasedOnVersionCallBeta } from "../../beta/simulator/Components/ducks/saga";
import {
    watchGetContextualRecommendationInitiate,
    watchGetContextualRecommendationSSE
} from "../../beta/simulator/Components/ContentOptimization/Editor/Components/ContentEditor/ContextualRecommendationsModal/ducks/sagas";

import {
    watchGetPerformanceImpactReportsList,
    watchAddPerformanceImpactReport,
    watchAGetPerformanceImpactReportDetails,
    watchAGetPerformanceImpactReportRankingKeywords,
    watchAGetPerformanceImpactReportGSCKeywords,
    watchAGetPerformanceImpactReportGSCSummary
} from "../../performance-impact/ducks/saga";

import {
    watchFetchSubDomains,
    watchFetchPackData
} from "../../serp-dashboard/ducks/saga";

import {
    watchAuthUserChangePwd,
    watchAuthUserForgotPwd,
    watchAuthUserResetPwd,
    watchChangeuserTenant,
    watchCheckSSOUserLogin,
    watchUserLogin,
    watchUserLoginSSO,
    watchUserLoginWithTenant,
    watchUserLogout
} from "../../user_account/ducks/saga";

// tslint:disable-next-line:typedef
export default function* rootSaga() {
    yield all([
        watchForCallToGetMultiKeywordRequestIdsBeta(),
        watchForCallToGetMultiKeywordTrackRequestStatusBeta(),
        watchForCallToGetThemeKeywordFromProjectBeta(),
        watchForCallFileUploadMkwBeta(),
        watchForCallToGetRelatedKeywordRequestIdsBeta(),
        watchForCallToGetRelatedKeywordTrackRequestStatusBeta(),
        watchForCallToGetRelatedKeywordTrackRequestStatusViaKeywordParam(),
        watchForCallToGetRelatedKeywordNonLiveTrackRequestStatusBeta(),
        watchForCallFetchCompetitorsScoreApiBeta(),
        watchCallToGetRequestIdOnSubmit(),
        watchCallSetContext(),
        watchForUserDetailsRequest(),
        watchForSubscriptionRequest(),
        watchForCallToGetRequestIdApp(),
        watchForGetTrackRequestCall(),
        watchFetchReportToken(),
        watchForKwResearchGetRequestId(),
        watchForCallToGetKwResearchRelatedKeywordTrackRequestStatus(),
        // simulator-beta sagas
        watchForModelScoreCallBeta(),
        watchForKeywordStuffingCallBeta(),
        watchForModelScoreDetailCallBeta(),
        watchForPerformanceScoreCallBeta(),
        watchForOnPageElementsCallBeta(),
        watchforPostSimulationCallBeta(),
        watchForCompetitorsScoreCallBeta(),
        watchforPreSimKeywordLevelDetailsBeta(),
        watchForVersionListCallBeta(),
        watchForScoresBasedOnVersionCallBeta(),
        watchForSnapShotMetaInformationBeta(),
        watchForContentBasedOnVersionCallBeta(),
        watchForUpdateDescriptionBeta(),
        watchForSimulationMetaInfoBeta(),
        watchForAuthTechParamsBasedOnVersionCallBeta(),
        watchForCompetitorScoresBasedOnVersionCallBeta(),
        watchForSimulationVersionStatusBeta(),
        watchForGetSerpDataCallBeta(),
        watchForGetKeywordUrlBeta(),
        watchForViewAllTopCompatitorBeta(),
        watchForDownloadSelectBeta(),
        watchForDownloadUrlReportBeta(),
        watchForGetParameterDetailBeta(),
        watchForGetUrlScoreBeta(),
        watchCallToClearUserSelectionBeta(),
        watchForGetProjectDetailsBeta(),
        watchForGetTenantLimitsBeta(),
        watchForDeleteProject(),
        watchForCallFileUploadBeta(),
        watchGetsaveSimulationHistoryBeta(),
        watchForInitialGeneratedContentOutlineCall(),
        watchForReGeneratedContentOutlineCall(),
        watchGetContentBriefData(),
        watchForAddContentBriefKeywords(),
        watchForUpdateCBNameAndDescription(),
        watchForGetContentBriefSummary(),
        watchForGetContentBriefTabHistory(),
        watchForGetContentBriefCompetitors(),
        watchFetchDefaultProjectCategory(),
        watchForgetSuggestedRelatedKWReqIds(),
        watchForgetSuggestedRelatedKW(),
        watchForPhrasesToUse(),
        watchForOnGetContentBriefProgress(),
        watchFetchTargetDomainFilterDetails(),
        watchfetchProjectsList(),
        watchFetchProjectList(),
        watchForOnUpdateContentBrief(),
        watchGetTenant(),
        watchGetLoginDetails(),
        watchFetchAllProjectsList(),
        watchForUserVerification(),
        watchForReverifyEmail(),
        watchCreateProjectAPICall(),
        watchCallToSaveProjectKeywords(),
        watchUpdateProjectDetailsAPICall(),
        watchGetProjectDetailsAPICall(),
        watchAddCategoryAPICall(),
        watchGetKeywordDetailsAPICall(),
        watchGetCategoryListAPICall(),
        watchDeleteCategoryAPICall(),
        watchEditCategoryAPICall(),
        watchGetSiteURLSListAPICall(),
        watchGetCustomerIdsListAPICall(),
        watchUnlinkGoogleAdsAccountAPICall(),
        watchSubmitCustomerIdsAPICall(),
        watchUnlinkAccountAPICall(),
        watchSubmitSiteURLAPICall(),
        watchMapKeywordAndCategories(),
        watchAddToOptimizationKeywords(),
        watchAssignNewCategoriesAPICall(),
        watchUpdateKeywordDetailsAPICall(),
        watchDeleteKeywordsFromProject(),
        watchGetCompetitorsListAPICall(),
        watchPostCompetitorsDomainsAPICall(),
        watchDeleteCompetitorsDomainsAPICall(),
        watchGetSuggestedCompetitorDomains(),
        watchcallToSaveUploadProjectKeyword(),
        watchForFetchPhraseContent(),
        watchGetSimulationDownload(),
        watchGetSimulationContentOnlyDownload(),
        watchPageAssessmentDownload(),
        watchCallRefreshProjectCache(),
        watchFetchSOVTargetCompetitorDomains(),
        watchFetchDefaultProjectTADomain(),
        watchGetCompetitorScoreSSEReqId(),
        watchGetCompetitorScoreSSE(),
        watchGetCompetitiorScoreNewKeywordReqId(),
        watchGetRecomBulbSubHeaderScores(),
        // Custom Reporting
        watchFetchTableauEmbedToken(),
        watchGetSimulationContentOnlyInitiateDownload(),
        watchGetSimulationContentOnlyTrackDownload(),
        watchGetContextualRecommendationInitiate(),
        watchGetContextualRecommendationSSE(),
        // Performance Impact
        watchGetPerformanceImpactReportsList(),
        watchAddPerformanceImpactReport(),
        watchAGetPerformanceImpactReportDetails(),
        watchAGetPerformanceImpactReportRankingKeywords(),
        watchAGetPerformanceImpactReportGSCKeywords(),
        watchAGetPerformanceImpactReportGSCSummary(),

        // User Account
        watchChangeuserTenant(),
        watchUserLoginWithTenant(),
        watchUserLogout(),
        watchCheckSSOUserLogin(),
        watchUserLogin(),
        watchAuthUserForgotPwd(),
        watchAuthUserChangePwd(),
        watchAuthUserResetPwd(),
        watchUserLoginSSO(),

        // Serp Dashboard
        watchFetchSubDomains(),
        watchFetchPackData()
    ]);
}
