import { AnyAction } from "redux";
import { takeEvery, call, put } from "redux-saga/effects";
// import { downloadToXlsx } from "../../app/duck/utils";
import Apis from "../../app/apis";
import actionTypes, {
    callToGetProjectDetails,
    callToGetCategoryList,
    saveProjectDetails,
    saveSubDomainList,
    setKeywordsAddedSuccess,
    saveCategoryList,
    saveKeywordDetails,
    saveSiteURLSList,
    setProjectEditJourney,
    saveAddedKeywordIDs,
    callToAssignCategoryPresent,
    callToGetKeywordData,
    saveListOfOptimizedKeywords,
    saveCompetitorsList,
    setStatusCodeOfSaveCompetitorAPI,
    callToGetCompetitorsList,
    setIsKwResearchAddCategorySuccess,
    setIsAddKeywordLimitExceeded,
    setStatusCodeOfSubmitSiteurlAPI,
    setMkNotificationDetails,
    setIsOptimizationKeywordsSuccess,
    setAddKwNotificationDetails,
    saveSuggestedCompetitorDomains,
    setSuggestedCompetitorDomainAPIStatus,
    setKeywordUploadFile,
    setIsUploadFileKeywordsSuccess,
    setStatusCodeOfCreateProjectAPI,
    setFilterKeywords,
    setProjectDevicetype,
    setErrorFileStatus,
    setErrorFileAPIResponse,
    setProjectInitialCrawlFreq,
    saveCustomerIdsList,
    setStatusCodeOfSubmitCustomerIdsAPI
} from "./actions";
import { getContext } from "../../app/duck/context-container";
import { isEmpty, isNull, isUndefined } from "lodash";
import { ManageKeywordNotifications } from "./types";
import { ALPS_LOCAL_STORAGE_KEY, COPY } from "../../app/const";
import ProjectSetupApis from "./apis";

const {
    ADD_CATEGORY_TOAST,
    TARGET_URL_ASSIGN_TOAST,
    KEYWORD_TYPE_TOAST,
    DELETE_KEYWORD_TOAST,
    ADD_TO_OPTIMIZATION_KEYWORD,
    MAP_KWRD_AND_CATEGORY,
    CALL_TO_SAVE_UPLOAD_PROJECT_KEYWORD
} = ManageKeywordNotifications;

// tslint:disable-next-line:typedef
function* callToCreateProject(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const createProjectPayload = {
        domain_url: action.payload.domain_url,
        name: action.payload.name,
        locale: action.payload.locale,
        is_default_project: action.payload.is_default_project,
        is_mobile: action.payload.is_mobile,
        crawling_frequency: action.payload.crawling_frequency
    };
    const response = yield call(Apis.createProject, createProjectPayload, parser);
    yield put(setStatusCodeOfCreateProjectAPI(response.status));
    if (response.status === 201 && !isEmpty(action.payload.sub_domains)) {
        const createSubDomainPayload = {
            sub_domains: action.payload.sub_domains,
            projectID: response.data.id
        };
        yield call(Apis.createSubDomains, createSubDomainPayload, parser);
    }
    if (response.status === 201) {
        const preMeta = localStorage.getItem(ALPS_LOCAL_STORAGE_KEY);
        const ls = !isNull(preMeta) ? JSON.parse(preMeta) : {};
        ls.currentProjectIDUnderEdit = response.data.id;
        ls.currentProjectNameUnderEdit = response.data.name;
        localStorage.setItem(ALPS_LOCAL_STORAGE_KEY, JSON.stringify(ls));
        yield put(callToGetProjectDetails({ projectID: response.data.id }));
        yield put(setProjectEditJourney({ id: response.data.id }));
    }
}

// tslint:disable-next-line:typedef
function* updateProjectDetails(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const updateProjectDetailsAPIPayload = {
        name: action.payload.name,
        is_default_project: action.payload.is_default_project,
        id: action.payload.id,
        is_mobile: action.payload.is_mobile,
        crawling_frequency: action.payload.crawling_frequency
    };

    if (!isEmpty(action.payload.subDomainIdsToBeRemoved)) {
        const deleteSubDomainAPIPayload = {
            projectID: action.payload.id,
            ids: action.payload.subDomainIdsToBeRemoved
        };
        yield call(Apis.deleteSubDomainsCall, deleteSubDomainAPIPayload, parser);
    }
    if (!isEmpty(action.payload.subDomains)) {
        const createSubDomainPayload = {
            sub_domains: action.payload.subDomains,
            projectID: action.payload.id
        };
        yield call(Apis.createSubDomains, createSubDomainPayload, parser);
    }
    const response = yield call(Apis.updateProjectDetails, updateProjectDetailsAPIPayload, parser);
    yield put(setStatusCodeOfCreateProjectAPI(response.status));
    if (response.status === 200) {
        const preMeta = localStorage.getItem(ALPS_LOCAL_STORAGE_KEY);
        const ls = !isNull(preMeta) ? JSON.parse(preMeta) : {};
        ls.currentProjectIDUnderEdit = response.data.id;
        ls.currentProjectNameUnderEdit = response.data.name;
        localStorage.setItem(ALPS_LOCAL_STORAGE_KEY, JSON.stringify(ls));
        yield put(callToGetProjectDetails({ projectID: response.data.id }));
    }
}

// tslint:disable-next-line:typedef
function* getProjectDetails(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.getProjectDetails, action.payload, parser);
    yield call(Apis.getSubDomainList, action.payload, parser);
    if (response.status === 200) {
        yield put(setProjectDevicetype(response.data.is_mobile));
        yield put(setProjectInitialCrawlFreq(response.data.crawling_frequency));
        yield put(saveProjectDetails(response.data));
    }
    const isSubDomainApiNotCall = action.payload.isSubDomainApiNotCall || false;
    if (!isSubDomainApiNotCall) {
        const subDomainListResponse = yield call(Apis.getSubDomainList, action.payload, parser);
        if (subDomainListResponse.status === 200) {
            yield put(saveSubDomainList(subDomainListResponse.data));
        }
    }
}

// tslint:disable-next-line: typedef
function* callToSaveProjectKeywords(action: AnyAction) {
    yield put(setKeywordsAddedSuccess(false));
    const { tenantCode = "" } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const responseObj = yield call(Apis.saveProjectKeywords, action.payload, parser);
    if (responseObj.status === 200) {
        yield put(setKeywordsAddedSuccess(true));
        yield put(saveAddedKeywordIDs(responseObj.data));
        yield put(setIsAddKeywordLimitExceeded(undefined));
    }
    if (responseObj.status === 429) {
        yield put(setIsAddKeywordLimitExceeded(responseObj.response.message));
    }
}

// tslint:disable-next-line:typedef
function* getKeywordData(action: AnyAction) {
    const { tenantCode = "" } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.getKeywordDetails, action.payload, parser);
    if (response.status === 200) {
        yield put(saveKeywordDetails(response.data));
        yield put(setFilterKeywords());
        yield put(saveListOfOptimizedKeywords(response.data));
    }
}

// tslint:disable-next-line:typedef
function* getCategoryList(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.getCategoryList, action.payload, parser);
    if (response.status === 200) {
        yield put(saveCategoryList(response.data));
    }
}

// tslint:disable-next-line:typedef
function* mapKeyordAndCategories(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.mapKeywordAndCategory, action.payload, parser);
    if (response.status === 201 || response.status === 200) {
        yield put(setAddKwNotificationDetails({ name: MAP_KWRD_AND_CATEGORY, value: true }));
        yield put(setIsKwResearchAddCategorySuccess(true));
    } else {
        yield put(setAddKwNotificationDetails({ name: MAP_KWRD_AND_CATEGORY, value: false }));
        yield put(setIsKwResearchAddCategorySuccess(false));
    }
}

// tslint:disable-next-line:typedef
function* addToOptimizationKeywords(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.addToOptimizationKeywords, action.payload, parser);
    if (response.status === 201 || response.status === 200) {
        yield put(setAddKwNotificationDetails({ name: ADD_TO_OPTIMIZATION_KEYWORD, value: true }));
        yield put(setIsOptimizationKeywordsSuccess(true));
    } else {
        yield put(setAddKwNotificationDetails({ name: ADD_TO_OPTIMIZATION_KEYWORD, value: false }));
        yield put(setIsOptimizationKeywordsSuccess(false));
    }
}

// tslint:disable-next-line:typedef
function* addCategory(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const payload = {
        categories: [action.payload.categoryName],
        projectID: action.payload.projectID
    };
    const response = yield call(Apis.addNewCategory, payload, parser);
    if (response.status === 200 || response.status === 201) {
        yield put(callToGetCategoryList({ projectID: action.payload.projectID }));
        yield put(callToAssignCategoryPresent());
    }
    if (response.status === 400) {
        yield put(callToAssignCategoryPresent(true));
    }
}

// tslint:disable-next-line:typedef
function* callToSaveUploadProjectKeyword(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.uploadFileKeyword, action.payload, parser);
    yield put(setErrorFileStatus(false));
    if (response.status === 201 || response.status === 200) {
        yield put(setIsUploadFileKeywordsSuccess(true));
        yield put(setAddKwNotificationDetails({ name: CALL_TO_SAVE_UPLOAD_PROJECT_KEYWORD, value: true }));
    } else if (response.status === 400 && response.response.type !== "application/json") {
        yield put(setIsUploadFileKeywordsSuccess(false));
        yield put(setKeywordUploadFile(COPY.ERROR_EXCEL_FILE));
        yield put(setErrorFileStatus(true));
        yield put(setErrorFileAPIResponse(response.response));
    } else if (response.status === 400) {
        const message: string = yield response.response.text();
        yield put(setKeywordUploadFile(JSON.parse(message).message));
        yield put(setIsUploadFileKeywordsSuccess(false));
    } else {
        yield put(setIsUploadFileKeywordsSuccess(true));
        yield put(setAddKwNotificationDetails({ name: CALL_TO_SAVE_UPLOAD_PROJECT_KEYWORD, value: false }));
    }
}

// tslint:disable-next-line:typedef
function* deleteCategory(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const payload = {
        id: [action.payload.categoryID],
        projectID: action.payload.projectID
    };
    const response = yield call(Apis.deleteCategory, payload, parser);
    if (response.status === 200) {
        yield put(callToGetCategoryList({ projectID: action.payload.projectID }));
    }
}

// tslint:disable-next-line:typedef
function* editCategory(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const payload = {
        category: action.payload.categoryName,
        id: action.payload.categoryID,
        projectID: action.payload.projectID
    };
    const response = yield call(Apis.editCategoryName, payload, parser);
    if (response.status === 200) {
        yield put(callToGetCategoryList({ projectID: action.payload.projectID }));
    }
}

// tslint:disable-next-line: typedef
function* assignNewCategories(action: AnyAction) {
    const projectID = action.payload.projectID;
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.postAssignNewCategories, action.payload, parser);
    if (response.status === 200 || response.status === 201) {
        yield put(callToGetKeywordData({ projectID }));
        yield put(callToGetCategoryList({ projectID }));
        yield put(setMkNotificationDetails({ name: ADD_CATEGORY_TOAST, value: true }));
    } else {
        yield put(setMkNotificationDetails({ name: ADD_CATEGORY_TOAST, value: false }));
    }
}

// tslint:disable-next-line:typedef
function* updateKeywordDetails(action: AnyAction) {
    let notificationName;
    if (!isUndefined(action.payload.is_optimized)) {
        notificationName = KEYWORD_TYPE_TOAST;
    }
    if (!isUndefined(action.payload.target_url)) {
        notificationName = TARGET_URL_ASSIGN_TOAST;
    }
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.updateKeywordDetails, action.payload, parser);
    if (response.status === 200) {
        yield put(callToGetKeywordData({ projectID: action.payload.projectID }));
        yield put(
            setMkNotificationDetails({
                name: notificationName,
                value: true,
                ...(!isUndefined(action.payload.is_optimized) && { isOptimized: action.payload.is_optimized })
            })
        );
    } else {
        yield put(setMkNotificationDetails({ name: notificationName, value: false }));
    }
}

// tslint:disable-next-line:typedef
function* deleteKeywordFromProject(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.deleteKeywordsFromProject, action.payload, parser);
    if (response.status === 200) {
        yield put(callToGetKeywordData({ projectID: action.payload.projectID }));
        yield put(callToGetCategoryList({ projectID: action.payload.projectID }));
        yield put(setMkNotificationDetails({ name: DELETE_KEYWORD_TOAST, value: true }));
    } else {
        yield put(setMkNotificationDetails({ name: DELETE_KEYWORD_TOAST, value: false }));
    }
}

// tslint:disable-next-line:typedef
function* getCompetitorsList(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.getCompetitorsList, action.payload, parser);
    if (response.status === 200) {
        yield put(saveCompetitorsList(response.data));
    }
}

// tslint:disable-next-line:typedef
function* submitCompetitorsDomains(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const payload = {
        competitor_urls: action.payload.urlList,
        projectID: action.payload.projectID
    };
    const response = yield call(Apis.submitCompetitorsDomains, payload, parser);
    yield put(setStatusCodeOfSaveCompetitorAPI(response.status));
    if (response.status === 200) {
        yield put(callToGetCompetitorsList({ projectID: action.payload.projectID }));
    }
}

// tslint:disable-next-line:typedef
function* deleteCompetitorsDomains(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const payload = {
        ids: action.payload.ids,
        projectID: action.payload.projectID
    };
    const response = yield call(Apis.deleteCompetitorsDomains, payload, parser);
    yield put(setStatusCodeOfSaveCompetitorAPI(response.status));
}

// tslint:disable-next-line:typedef
function* getSiteURLSList(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.getSiteURLSList, action.payload, parser);
    if (response.status === 200) {
        yield put(saveSiteURLSList(response.data.site_urls));
    }
}

// tslint:disable-next-line:typedef
function* unlinkGSCAccount(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.unlinkGSCAccount, action.payload, parser);
    if (response.status === 200) {
        yield put(callToGetProjectDetails({ projectID: action.payload.projectID }));
    }
}

// tslint:disable-next-line:typedef
function* submitSiteURL(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.submitSiteURL, action.payload, parser);
    yield put(setStatusCodeOfSubmitSiteurlAPI(response.status));
}

// tslint:disable-next-line:typedef
function* getCustomerIdsList(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(ProjectSetupApis.getCustomerIdsList, action.payload, parser);
    if (response.status === 200) {
        yield put(saveCustomerIdsList(response.data.data));
    }
}

// tslint:disable-next-line:typedef
function* unlinkGoogleAdsAccount(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(ProjectSetupApis.unlinkGoogleAdsAccount, action.payload, parser);
    if (response.status === 200) {
        yield put(callToGetProjectDetails({ projectID: action.payload.projectID }));
    }
}

// tslint:disable-next-line:typedef
function* submitCustomerIds(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(ProjectSetupApis.submitCustomerIds, action.payload, parser);
    yield put(setStatusCodeOfSubmitCustomerIdsAPI(response.status));
}

// tslint:disable-next-line:typedef
function* getSuggestedCompetitorDomains(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };

    const response = yield call(Apis.getSuggestedCompetitorDomains, action.payload, parser);
    if (response.status === 200) {
        yield put(saveSuggestedCompetitorDomains(response.data));
    }
    yield put(setSuggestedCompetitorDomainAPIStatus(true));
}

// tslint:disable-next-line:typedef
export function* watchCreateProjectAPICall() {
    yield takeEvery(actionTypes.CALL_TO_CREATE_PROJECT, callToCreateProject);
}

// tslint:disable-next-line:typedef
export function* watchCallToSaveProjectKeywords() {
    yield takeEvery(actionTypes.CALL_TO_SAVE_PROJECT_KEYWORDS, callToSaveProjectKeywords);
}

// tslint:disable-next-line: typedef
export function* watchUpdateProjectDetailsAPICall() {
    yield takeEvery(actionTypes.CALL_TO_UPDATE_PROJECT_DETAILS, updateProjectDetails);
}

// tslint:disable-next-line:typedef
export function* watchGetProjectDetailsAPICall() {
    yield takeEvery(actionTypes.CALL_TO_GET_PROJECT_DETAILS, getProjectDetails);
}

// tslint:disable-next-line:typedef
export function* watchGetKeywordDetailsAPICall() {
    yield takeEvery(actionTypes.CALL_TO_GET_KEYWORD_DATA, getKeywordData);
}

// tslint:disable-next-line:typedef
export function* watchMapKeywordAndCategories() {
    yield takeEvery(actionTypes.MAP_KEYWORD_AND_CATEGORIES, mapKeyordAndCategories);
}

// tslint:disable-next-line:typedef
export function* watchAddToOptimizationKeywords() {
    yield takeEvery(actionTypes.ADD_TO_OPTIMIZATION_KEYWORDS, addToOptimizationKeywords);
}

// tslint:disable-next-line:typedef
export function* watchGetCategoryListAPICall() {
    yield takeEvery(actionTypes.CALL_TO_GET_CATEGORY_LIST, getCategoryList);
}

// tslint:disable-next-line:typedef
export function* watchAddCategoryAPICall() {
    yield takeEvery(actionTypes.CALL_TO_ADD_CATEGORY, addCategory);
}

// tslint:disable-next-line:typedef
export function* watchDeleteCategoryAPICall() {
    yield takeEvery(actionTypes.CALL_TO_DELETE_CATEGORY, deleteCategory);
}

// tslint:disable-next-line:typedef
export function* watchEditCategoryAPICall() {
    yield takeEvery(actionTypes.CALL_TO_EDIT_CATEGORY, editCategory);
}

// tslint:disable-next-line:typedef
export function* watchGetSiteURLSListAPICall() {
    yield takeEvery(actionTypes.CALL_TO_GET_SITE_URLS_LIST, getSiteURLSList);
}

// tslint:disable-next-line:typedef
export function* watchGetCustomerIdsListAPICall() {
    yield takeEvery(actionTypes.CALL_TO_GET_CUSTOMER_IDS_LIST, getCustomerIdsList);
}

// tslint:disable-next-line:typedef
export function* watchUnlinkGoogleAdsAccountAPICall() {
    yield takeEvery(actionTypes.CALL_TO_GET_UNLINK_GOOGLE_ADS_ACCOUNT, unlinkGoogleAdsAccount);
}

// tslint:disable-next-line:typedef
export function* watchSubmitCustomerIdsAPICall() {
    yield takeEvery(actionTypes.CALL_TO_SUBMIT_CUSTOMER_IDS, submitCustomerIds);
}

// tslint:disable-next-line:typedef
export function* watchUnlinkAccountAPICall() {
    yield takeEvery(actionTypes.CALL_TO_GET_UNLINK_GSC_ACCOUNT, unlinkGSCAccount);
}

// tslint:disable-next-line:typedef
export function* watchSubmitSiteURLAPICall() {
    yield takeEvery(actionTypes.CALL_TO_SUBMIT_SITE_URL, submitSiteURL);
}
// tslint:disable-next-line: typedef
export function* watchAssignNewCategoriesAPICall() {
    yield takeEvery(actionTypes.CALL_TO_ASSIGN_NEW_CATEGORIES, assignNewCategories);
}

// tslint:disable-next-line:typedef
export function* watchUpdateKeywordDetailsAPICall() {
    yield takeEvery(actionTypes.UPDATE_KEYWORD_DETAILS, updateKeywordDetails);
}

// tslint:disable-next-line:typedef
export function* watchDeleteKeywordsFromProject() {
    yield takeEvery(actionTypes.DELETE_KEYWORDS_FROM_PROJECT, deleteKeywordFromProject);
}

// tslint:disable-next-line:typedef
export function* watchGetCompetitorsListAPICall() {
    yield takeEvery(actionTypes.CALL_TO_GET_COMPETITORS_LIST, getCompetitorsList);
}

// tslint:disable-next-line:typedef
export function* watchPostCompetitorsDomainsAPICall() {
    yield takeEvery(actionTypes.CALL_TO_POST_COMPETITOR_DOMAINS, submitCompetitorsDomains);
}

// tslint:disable-next-line:typedef
export function* watchDeleteCompetitorsDomainsAPICall() {
    yield takeEvery(actionTypes.CALL_TO_DELETE_COMPETITOR_DOMAINS, deleteCompetitorsDomains);
}

// tslint:disable-next-line:typedef
export function* watchGetSuggestedCompetitorDomains() {
    yield takeEvery(actionTypes.CALL_TO_GET_SUGGESTED_COMPETITOR_DOMAINS, getSuggestedCompetitorDomains);
}

// tslint:disable-next-line:typedef
export function* watchcallToSaveUploadProjectKeyword() {
    yield takeEvery(actionTypes.CALL_TO_SAVE_UPLOAD_PROJECT_KEYWORD, callToSaveUploadProjectKeyword);
}
