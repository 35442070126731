import { AnyAction } from "redux";
import { put, takeEvery, call, select, cancel, race, cancelled, take, delay } from "redux-saga/effects";
import { isUndefined, omit, omitBy, isEmpty, filter } from "lodash";
import {
    CONTENT_SIMULATOR_PARAMETERS_ORDER_LIST_BETA,
    DEFAULT_EDITOR_CONTENT_BETA
} from "../../../app/const";

import Apis from "./apis";
import actionTypes, {
    setAggregatedPerformanceScore,
    setModelScore,
    setModelScoreDetails,
    setOnPageElements,
    setSimulatedScore,
    setRevokeLoader,
    setCompetitorsScore,
    setFetchProcessStatus,
    setKeywordLevelImpactedPerformanceScore,
    setPreSimKeywordDetails,
    SettingOnPageStatusCode,
    setFetchPerformanceScorePassed,
    SetVersionList,
    setCrawlDate,
    setScoreBasedOnVersion,
    setOnPageElementsBasedOnVersion,
    getSimulationMetaInfo,
    callToCaptureSnapshotOfMetaInformation,
    callToGetVersionList,
    setSaveDescription,
    updateSidToSaveCompetitorsScore,
    setSimulationsVersionChangeFlag,
    setSimulationVersionStatus,
    callToResetIsSimulateDone,
    setFirstTimeRenderingFlag,
    resetTechAuthChangedParams,
    setBrowserEditorSimulatedScore,
    setOnBrowserEditorBasedOnVersion,
    setFormattedOnPageElements,
    setEditorMode,
    setBrowserContent,
    setIsBrowserMode,
    setIsFetchContentOnSwitchEditorMode,
    setOnPageBrowserContent,
    setIsContentFetched,
    setKeywordStuffDetails,
    setIsDownloadSimulation,
    setIsDownloadFailed,
    setIsDownloadPAFailed,
    setChangedUrlInFilterUrlList,
    callToGetCometitorScoreSSE,
    setCompetitorScoreSSeRetriedAttempt,
    setUrlKeywordsScore,
    competitorsScoreSSELoading,
    setOriginalWordCount,
    getCompetitorsScoreBasedOnVersion,
    callToGetCompetitorsScoreSSEReqid,
    setTopCompFetchStatus,
    setMetaInfoFetchStatus,
    setRecomBulbSubHeaderScores,
    callRecomBulbSubHeaderScores,
    setAllKWsProcessed,
    setTechDataFailed,
    setIsViewRecommendationOpen,
    callToGetCompetitorScoreNewKeyword,
    setSavedSimulationCalledBeforeTrackReqComp,
    getAuthTechParams,
    callSimulationDownloadContentOnlyTrackApi,
    setIsDownloadPageAssessment
} from "./actions";

import {
    PerformanceMetricsPayloadInterface,
    ModelScorePayloadInterface,
    AggregationTypes,
    SimulateModelScoreDetailsPayloadInterface,
    CompetitorsScorePayloadInterface,
    SimulatePerformanceTypeInterface,
    ModelScoreDetailsPayloadInterface,
    ModelScoreDetailsResponseInterface,
    LiveNonLivePerformanceMetricsPayloadInterface,
    VersionListInputPayloadInterface,
    MetaInformationInterface,
    CaptureTechAuthInformationPayloadInterface,
    EditorElementInterfaceBeta,
    SimulateKeywordStuffingPayloadInterface,
    CompetitorsScoreInitiatePayloadInterface,
    OnPageElementsTypeBeta
} from "./types";
import {
    formatOnPageElements,
    formatOnPageChangedElement,
    getChangedDataBasedOnVersion,
    getKeywordRequestIds,
    getKeywordProcessedInfo,
    getUpdatedPageContent,
    getModifiedPageContent,
    isBrowserModeExist,
    downloadSimulationContent,
    listenCompetitorScoreSSEvents,
    getFormatedKeywordList,
    getSubHeadersFromEditor,
    validateRecomBulbSubHeaderScoresPayload
} from "./utils";
import { getContext } from "../../../app/duck/context-container";
import { CategoryType } from "../../../app/duck/types";
import {
    selectedMultiKeywordLocale,
    setEnableCompetitorApi,
    setEnableSimulatePerformance,
    updateKeywordDetailsBasedOnVersion,
    updateProcessedKeywordsOnVersionChange
} from "../Components/AddModifyKeyword/ducks/actions";
import {
    getCompetitorScoreSSeRetriedAttempts,
    getPageChangedContent,
    getPageContent,
    getProcessedKeywords,
    getSidToSaveCompetitorScore,
    getTrackRequestStatus
} from "../ducks/selector";
import { setSerpProgressStatusFromHistoryTable } from "../../../app/duck/actions";
import {
    getSimulationsVersionChangeFlag,
    getMultiKeywordLocale,
    getContextLocale,
    getEnableSimulateScore,
    getAllKeywords,
    getSimulatePerformanceFlag
} from "../Components/AddModifyKeyword/ducks/selector";
import {
    setIsSimulationDone,
    setSimulationKeywords,
    setVersionKeywords
} from "../ducks/actions";
import { ApiConfig } from "../../../app/config/apiConfig";
import { ApiPath } from "../../../app/config/apiPath";
import { getTitle, googleTagManagerDataLayer } from "../../../app/duck/utils";
import { getAIAssistedContentFlag, getUserPrompt } from "../../../beta/page-optimizer/ducks/selector";
import { setIsDescriptionUpdateFailed } from "../Components/ducks/actions";
import { getKeywords } from "../ducks/utils";
import {
    CLS,
    FID,
    LCP,
    FETCH_TIME,
    HTML_SIZE,
    SIMULATOR_CONST,
    SIMULATION_DOWNLOAD_CONTENT_FILE_NAME,
    DEFAULT_EDITOR_CONTENT,
    RunSimulationErrors,
    SIMULATION_DOWNLOAD_FILE_NAME,
    COMPETITOR_SCORE_SSE_EVENT_TYPES,
    SIMULATION_DOWNLOAD_ASSESSMENT_FILE_NAME,
    FIB_REQ_DELAY,
    SIMULATION_DOWNLOAD_PERFIX
} from "./const";
import { EDITOR_MODES } from "../Components/ducks/const";
import { getfilteredUrl } from "../Components/CompetitiveAnalysis/ducks/selector";

// tslint:disable-next-line:typedef
function* fetchPerformanceScore(action: AnyAction) {
    const { selectedKeyword, selectedLocale, tenantCode } = getContext();
    const { keywords = [selectedKeyword], locale = selectedLocale, url, is_url_live, notifyErrorMethod } = action.payload;
    const payload: LiveNonLivePerformanceMetricsPayloadInterface = {
        keywords,
        locale,
        url,
        is_url_live,
        ...(notifyErrorMethod && { notifyErrorMethod }),
        needToHandleError: true
    };
    // make singleton getter for context
    const parser = {
        tenantCode: tenantCode as string
    };

    const scores = yield call(Apis.fetchPerformanceScore, payload, parser);
    const status = scores.status;
    if (status === 200) {
        if (action.payload.fetchOnlyImpactedKeyword) {
            yield put(setKeywordLevelImpactedPerformanceScore(scores.data.keyword_data));
        } else {
            yield put(setAggregatedPerformanceScore(scores.data));
        }
    } else if (status === 204) {
        if (action.payload.fetchOnlyImpactedKeyword) {
            yield put(setKeywordLevelImpactedPerformanceScore(scores.data.keyword_data));
        } else {
            yield put(setAggregatedPerformanceScore(scores.data));
        }
    } else if (status >= 400 && status < 600) {
        yield put(setFetchPerformanceScorePassed(false));
    }
}
// tslint:disable-next-line:typedef
function* fetchModelScore(action: AnyAction) {
    const { tenantCode } = getContext();
    const { keywords, locale, url, notifyErrorMethod, are_competitors_processed } = action.payload;
    const payload: ModelScorePayloadInterface = {
        ...(keywords && { keywords }),
        ...(locale && { locale }),
        ...(notifyErrorMethod && { notifyErrorMethod }),
        url,
        are_competitors_processed,
        isBeta: true,
        aggregation_type: AggregationTypes.WEIGHTED_AVG_SEARCH_VOLUME,
        needToHandleError: true,
        category:
            !isUndefined(keywords) && keywords.length > 0
                ? [CategoryType.CONTENT, CategoryType.AUTHORITY, CategoryType.TECHNICAL]
                : [CategoryType.AUTHORITY, CategoryType.TECHNICAL]
    };
    const isLive = isUndefined(action.payload.is_url_live) ? true : action.payload.is_url_live;
    // make singleton getter for context
    const parser = {
        tenantCode: tenantCode as string
    };

    const scores = yield call(Apis.fetchModelScore, payload, parser, isLive);
    if (scores.status === 200) {
        yield put(setModelScore(scores.data));
        yield put(setTopCompFetchStatus(true));
    } else if (scores.status === 204) {
        yield put(setModelScore(scores.data));
        yield put(setTopCompFetchStatus(false));
    } else {
        yield put(
            setFetchProcessStatus({
                isModelScoreFetched: false,
                isTechnicalScoreFetched: false,
                isAuthorityScoreFetched: false
            })
        );
        yield put(setTechDataFailed(true));
    }
}

// tslint:disable-next-line:typedef
function* fetchKeywordStuffingDetail(action: AnyAction) {
    const { tenantCode } = getContext();

    const payload: SimulateKeywordStuffingPayloadInterface = {
        url: action.payload.url,
        changed_dict: action.payload.changed_dict
    };

    const parser = {
        tenantCode: tenantCode as string
    };
    const scores = yield call(Apis.fetchKeywordStuffingDetail, payload, parser);

    if (scores.status === 200) {
        // const scoresDetailResponse: ModelScoreDetailsResponseInterface = scores.data as ModelScoreDetailsResponseInterface;
        const keyStuffDetail = scores.data;
        yield put(
            setKeywordStuffDetails({
                details: keyStuffDetail
            })
        );
    }
}
// tslint:disable-next-line:typedef
function* fetchModelScoreDetails(action: AnyAction) {
    const { tenantCode } = getContext();
    const { keywords, locale, url, is_url_live, notifyErrorMethod } = action.payload;
    const payload: ModelScoreDetailsPayloadInterface = {
        keywords,
        locale,
        url,
        is_url_live,
        content_model_score_list: CONTENT_SIMULATOR_PARAMETERS_ORDER_LIST_BETA,
        ...(notifyErrorMethod && { notifyErrorMethod }),
        isBeta: true,
        needToHandleError: true
    };
    // make singleton getter for context
    const parser = {
        tenantCode: tenantCode as string
    };
    const scores = yield call(Apis.fetchModelScoreDetail, payload, parser);

    if (scores.status === 200) {
        const scoresDetailResponse: ModelScoreDetailsResponseInterface = scores.data as ModelScoreDetailsResponseInterface;
        yield put(
            setModelScoreDetails({
                details: scoresDetailResponse
            })
        );
    } else if (scores.status === 204) {
        const scoresDetailResponse: ModelScoreDetailsResponseInterface = scores.data as ModelScoreDetailsResponseInterface;
        yield put(
            setModelScoreDetails({
                details: scoresDetailResponse
            })
        );
    } else {
        yield put(
            setFetchProcessStatus({
                isModelScoreDetailsFetched: false
            })
        );
    }
}

// tslint:disable-next-line:typedef
function* fetchOnPageElements(action: AnyAction) {
    const { tenantCode } = getContext();
    const { keywords, locale, url, request_id, notifyErrorMethod } = action.payload;
    const parser = {
        tenantCode: tenantCode as string
    };
    if (!action.payload.is_url_live && isUndefined(action.payload.request_id)) {
        const formatDataForNonLiveUrl = getModifiedPageContent(formatOnPageElements(DEFAULT_EDITOR_CONTENT_BETA), url);
        yield put(setOnPageElements(getUpdatedPageContent(formatDataForNonLiveUrl)));
        yield put(setFirstTimeRenderingFlag(true));
        yield put(resetTechAuthChangedParams());
    } else {
        const payload: PerformanceMetricsPayloadInterface = {
            url,
            ...(locale && { locale }),
            ...(keywords && { keywords }),
            ...(notifyErrorMethod && { notifyErrorMethod }),
            isBeta: true,
            needToHandleError: true
        };
        const fileUploadRequestId = {
            ...(request_id && { request_id, url })
        };
        const impactedKeywords = yield select(getProcessedKeywords);
        const multiKeywordLocale = yield select(getMultiKeywordLocale);
        const contextSelectedLocale = yield select(getContextLocale);

        const onPageElements = yield call(Apis.fetchOnPageElements, payload, parser, fileUploadRequestId);
        const copyOfonPageElements = JSON.parse(JSON.stringify(onPageElements));
        if (copyOfonPageElements.data?.content_crawl_date) {
            delete copyOfonPageElements.data.content_crawl_date;
        }

        // Browser content mode
        const isBrowserMode = action.payload.editorMode === EDITOR_MODES.browser;
        if (isBrowserMode && onPageElements.data) {
            const formattedOnPageElements = formatOnPageElements(onPageElements.data);
            yield put(setCrawlDate(onPageElements.data.content_crawl_date));
            yield put(setFormattedOnPageElements(formatOnPageElements(onPageElements.data)));
            yield put(setOnPageBrowserContent(formattedOnPageElements));
            yield put(SettingOnPageStatusCode(onPageElements.status));
            return;
        }

        if (onPageElements.status === 200) {
            yield put(setCrawlDate(onPageElements.data.content_crawl_date));
            const formattedOnPageElements = formatOnPageElements(copyOfonPageElements.data);
            yield put(setFormattedOnPageElements(formattedOnPageElements));
            yield put(setOnPageElements(getUpdatedPageContent(formattedOnPageElements)));
            const onPageElementStatusCode = onPageElements.status;
            yield put(SettingOnPageStatusCode(onPageElementStatusCode));

            const params = {
                keywords: getFormatedKeywordList(impactedKeywords),
                locale: multiKeywordLocale ? multiKeywordLocale : contextSelectedLocale,
                sub_header: getSubHeadersFromEditor()
            };
            const isCallRecomBulbApi = validateRecomBulbSubHeaderScoresPayload(params);
            if (isCallRecomBulbApi) {
                yield put(callRecomBulbSubHeaderScores(params));
            }

            // slector
        } else if (onPageElements.status === 206) {
            yield put(setCrawlDate(onPageElements.data.content_crawl_date));
            const onPageElementStatusCode = onPageElements.status;
            yield put(SettingOnPageStatusCode(onPageElementStatusCode));
            const formattedOnPageElements = formatOnPageElements(copyOfonPageElements.data);
            yield put(setFormattedOnPageElements(formattedOnPageElements));
            yield put(setOnPageElements(getUpdatedPageContent(formattedOnPageElements)));
            const params = {
                keywords: getFormatedKeywordList(impactedKeywords),
                locale: multiKeywordLocale ? multiKeywordLocale : contextSelectedLocale,
                sub_header: getSubHeadersFromEditor()
            };
            const isCallRecomBulbApi = validateRecomBulbSubHeaderScoresPayload(params);
            if (isCallRecomBulbApi) {
                yield put(callRecomBulbSubHeaderScores(params));
            }
        } else if (onPageElements.status === 204) {
            const onPageElementStatusCode = onPageElements.status;
            yield put(setCrawlDate(onPageElements.data.content_crawl_date));
            yield put(SettingOnPageStatusCode(onPageElementStatusCode));
            yield put(setRevokeLoader());
            const formattedOnPageElements = formatOnPageElements(copyOfonPageElements.data);
            yield put(setFormattedOnPageElements(formattedOnPageElements));
            yield put(setOnPageElements(getUpdatedPageContent(formattedOnPageElements)));
        } else {
            yield put(setRevokeLoader());
            yield put(
                setFetchProcessStatus({
                    isContentFetched: false
                })
            );
        }
    }
}
// tslint:disable-next-line:typedef
function* fetchPostSimulationScores(action: AnyAction) {
    const { selectedLocale, tenantCode } = getContext();
    const { keywords, locale = selectedLocale, url, notifyErrorMethod, project_id, sidNext } = action.payload;
    const { technical } = action.payload.changedParameters;
    let technicalChangedScore =
        !isUndefined(technical) && technical[FETCH_TIME]
            ? {
                ...technical,
                [FETCH_TIME]: Number(parseFloat(technical[FETCH_TIME]).toFixed(2))
            }
            : technical;

    technicalChangedScore =
        !isUndefined(technical) && technical[HTML_SIZE]
            ? {
                ...technicalChangedScore,
                [HTML_SIZE]: Number(parseFloat(technical[HTML_SIZE]).toFixed(2))
            }
            : technicalChangedScore;

    technicalChangedScore =
        !isUndefined(technical) && technical[CLS]
            ? {
                ...technicalChangedScore,
                [CLS]: Number(parseFloat(technical[CLS]).toFixed(2))
            }
            : technicalChangedScore;

    technicalChangedScore =
        !isUndefined(technical) && technical[FID]
            ? {
                ...technicalChangedScore,
                [FID]: Number(parseFloat(technical[FID]).toFixed(2))
            }
            : technicalChangedScore;

    technicalChangedScore =
        !isUndefined(technical) && technical[LCP]
            ? {
                ...technicalChangedScore,
                [LCP]: Number(parseFloat(technical[LCP]).toFixed(2))
            }
            : technicalChangedScore;

    const filteredUploadContent = omitBy(action.payload.htmlUploadContent, (item) => {
        return [OnPageElementsTypeBeta.MISSING_H1, OnPageElementsTypeBeta.MISSING_H2, OnPageElementsTypeBeta.MISSING_H3].includes(
            item.title
        );
    });
    const formattedChangedContentList = filter(action.payload.changedParameters.content, (el: EditorElementInterfaceBeta) => {
        return ![OnPageElementsTypeBeta.MISSING_H1, OnPageElementsTypeBeta.MISSING_H2, OnPageElementsTypeBeta.MISSING_H3].includes(
            el.title
        );
    });
    const is_url_live = action.payload.is_url_live;
    const payload: SimulateModelScoreDetailsPayloadInterface = {
        keywords,
        locale,
        url,
        is_url_live,
        simulation_type: SimulatePerformanceTypeInterface.PERFORMANCE,
        ...(notifyErrorMethod && { notifyErrorMethod }),
        needToHandleError: true,
        isSpecificError: true,
        errorList: RunSimulationErrors,
        changed_dict: {
            ...formatOnPageChangedElement(
                !isUndefined(action.payload.htmlUploadContent)
                    ? filteredUploadContent
                    : // tslint:disable-next-line: no-any
                    (formattedChangedContentList as any)
            ),
            ...technicalChangedScore,
            ...action.payload.changedParameters.authority
        },
        sid: sidNext,
        ...(project_id && { project_id }),
        isBeta: true
    };
    const parser = {
        tenantCode: tenantCode as string
    };
    let isSimulationVersionChanged = yield select(getSimulationsVersionChangeFlag);
    if (isSimulationVersionChanged) {
        yield put(setSimulationsVersionChangeFlag(false));
    }

    const isSavedSimulationCalledBeforeTrackReqComp = yield select(getSimulatePerformanceFlag);
    if (!isSavedSimulationCalledBeforeTrackReqComp) {
        yield put(setSavedSimulationCalledBeforeTrackReqComp({
            status: true, data: {
                ...action.payload,
                runInBackGround: true,
                isForceUpdate: false,
            }
        }));
    } else {
        yield put(setSavedSimulationCalledBeforeTrackReqComp({ status: false, data: {} }));
    }
    const simulatedDetails = yield call(Apis.fetchPostSimulationModeleScoreDetail, payload, parser);
    if (simulatedDetails.status === 200) {
        if (!is_url_live) {
            const filteredUrlsList = yield select(getfilteredUrl);
            yield put(setChangedUrlInFilterUrlList({ filteredUrlsList, url: payload.changed_dict.url }));
        }
        isSimulationVersionChanged = yield select(getSimulationsVersionChangeFlag);
        if (!isSimulationVersionChanged) {
            const postSimulation = {
                impactedKeywords: simulatedDetails.data && simulatedDetails.data.keyword_data,
                modelScore: simulatedDetails.data && simulatedDetails.data.content.aggregated_model_score,
                modelScoreDetails: {
                    ...simulatedDetails.data.content.model_score_details,
                    category_scores: {
                        ...simulatedDetails.data.content.model_score_details.category_scores,
                        ...simulatedDetails.data.scores
                    }
                },
                performance: simulatedDetails.data.aggregated_performance_metrics,
                authorityForcedChangeParameter:
                    simulatedDetails.data && simulatedDetails.data.authority && simulatedDetails.data.authority.parameters,
                technicalForcedChangeParameter:
                    simulatedDetails.data && simulatedDetails.data.technical && simulatedDetails.data.technical.parameters,
                simulationType: action.payload.simulateType,
                isStale: action.payload.isStale
            };
            if (action.payload.editorMode === EDITOR_MODES.browser) {
                yield put(setBrowserEditorSimulatedScore(postSimulation));
            } else {
                yield put(setSimulatedScore(postSimulation));
            }

            if (!action.payload.runInBackGround && !action.payload.isForceUpdate) {
                const isAIGeneratedContent = yield select(getAIAssistedContentFlag);
                const userPrompt = yield select(getUserPrompt);
                const metaInformationPayload: MetaInformationInterface = {
                    url,
                    locale,
                    is_url_live,
                    keywords: action.payload.allKeyword,
                    sid: sidNext,
                    ...(project_id && { project_id }),
                    description: action.payload.description,
                    is_browser_mode: action.payload.isBrowserMode,
                    is_ai_generated_content: isAIGeneratedContent ? 1 : 0,
                    user_prompt: userPrompt
                };
                yield put(callToCaptureSnapshotOfMetaInformation(metaInformationPayload));
                let originalDict = {};
                if (!is_url_live) {
                    const editorElements = yield select(getPageContent);
                    originalDict = formatOnPageChangedElement(editorElements);
                    originalDict = omit(originalDict, [
                        OnPageElementsTypeBeta.MISSING_H1,
                        OnPageElementsTypeBeta.MISSING_H2,
                        OnPageElementsTypeBeta.MISSING_H3
                    ]);
                }
                const simPayload = {
                    url,
                    keywords,
                    locale,
                    is_url_live,
                    sid: sidNext,
                    changed_dict: {
                        ...formatOnPageChangedElement(
                            !isUndefined(action.payload.htmlUploadContent)
                                ? filteredUploadContent
                                : // tslint:disable-next-line: no-any
                                (formattedChangedContentList as any)
                        )
                    },
                    // changed_dict: {
                    //     ...formatOnPageChangedElement(formattedChangedContentList)
                    // },
                    ...(!is_url_live && { original_dict: originalDict }),
                    isBeta: payload.isBeta
                };
                yield call(Apis.saveSimulationContent, simPayload, parser);
                const captureTechAuthPayload: CaptureTechAuthInformationPayloadInterface = {
                    url,
                    locale,
                    keywords,
                    sid: sidNext,
                    changed_dict: {
                        authority: {
                            ...action.payload.changedParameters.authority
                        },
                        technical: {
                            ...technicalChangedScore
                        }
                    },
                    isBeta: true,
                    ...(project_id && { project_id })
                };
                yield call(Apis.saveSnapshotAuthTechInformation, captureTechAuthPayload, parser);
            }
        }
    } else {
        yield put(setRevokeLoader());
        yield put(callToResetIsSimulateDone());
    }
}

// tslint:disable-next-line:typedef
function* fetchCompetitorsScore(action: AnyAction) {
    const { tenantCode } = getContext();
    const { keywords, search_url, project_id, versionSID, notifyErrorMethod, locale } = action.payload;
    const multiKeywordLocale = yield select(getMultiKeywordLocale);
    const payload: CompetitorsScorePayloadInterface = {
        keywords,
        search_url,
        locale: !isUndefined(multiKeywordLocale) ? multiKeywordLocale : locale,
        ...(versionSID && { sid: versionSID }),
        ...(project_id && { project_id }),
        ...(notifyErrorMethod && { notifyErrorMethod }),
        isBeta: true,
        needToHandleError: true
    };
    const parser = {
        tenantCode: tenantCode as string
    };
    const isSimulationVersionChanged = yield select(getSimulationsVersionChangeFlag);
    if (isSimulationVersionChanged || window.location.pathname !== "/simulation") {
        yield cancel();
    }
    const competitorsScore = yield call(Apis.fetchCompetitorsScore, payload, parser);
    if (competitorsScore.status === 200) {
        yield put(setCompetitorsScore(competitorsScore.data));
        yield put(setEnableCompetitorApi(true));
        yield put(
            callToGetCompetitorScoreNewKeyword({
                locale,
                search_url,
                keywords
            })
        );
        yield put(setIsViewRecommendationOpen(true));
        const sid = yield select(getSidToSaveCompetitorScore);
        if (!isEmpty(sid)) {
            yield call(Apis.saveCompetitorScores, { sid, isBeta: true }, parser);
            yield put(updateSidToSaveCompetitorsScore());
        }
    } else {
        yield put(setRevokeLoader());
    }
}

// tslint:disable-next-line: typedef
export function* callToGetCompetitorsScoreSSEReqId(action: AnyAction) {
    const { tenantCode } = getContext();
    const { locale, sid } = action.payload;
    const payload: CompetitorsScoreInitiatePayloadInterface = {
        locale,
        sid
    };
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.getCompetitorScoreInitiate, payload, parser);
    if (response.status === 200) {
        const parameter = {
            requestId: response.data.ref_id,
            token: response.data.token
        };

        yield put(callToGetCometitorScoreSSE(parameter));
    }
    return response;
}
// tslint:disable-next-line: typedef
export function* callToGetCompetitorsScoreNewKeywordSSEReqId(action: AnyAction) {
    const { tenantCode, projectId } = getContext();
    const { keywords, locale, search_url } = action.payload;
    const payload: CompetitorsScorePayloadInterface = {
        locale,
        keywords,
        search_url,
        project_id: projectId
    };
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.getCompetitorScoreNewKeywordInitiate, payload, parser);
    if (response.status === 200) {
        const parameter = {
            requestId: response.data.ref_id,
            token: response.data.token
        };

        yield put(callToGetCometitorScoreSSE(parameter));
    }
    return response;
}
// tslint:disable-next-line: typedef
export function* callToGetCometitorScoreAPISSE(action: AnyAction) {
    const { requestId, token } = action.payload;
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const url = `${ApiConfig.baseURL}/${ApiPath.SAVE_SIMULATION_COMPETITORS_SCORE_SSE.replace(
        "{{tenantCode}}",
        parser.tenantCode
    )}?ref_id=${requestId}&token=${token}`;
    const { channel, timeout } = yield race({
        channel: call(listenCompetitorScoreSSEvents, url),
        timeout: delay(600 * 1000)
    });

    const competitorScoreSSERetriedAttempt = yield select(getCompetitorScoreSSeRetriedAttempts);

    if (timeout) {
        if (competitorScoreSSERetriedAttempt < 3) {
            const parameter = {
                requestId,
                token
            };
            channel.close();
            yield put(setCompetitorScoreSSeRetriedAttempt(competitorScoreSSERetriedAttempt + 1));
            yield put(callToGetCometitorScoreSSE(parameter));
        }
        channel.close();
        yield put(competitorsScoreSSELoading(COMPETITOR_SCORE_SSE_EVENT_TYPES.error));
    }
    try {
        while (true) {
            try {
                const response = yield take(channel);
                if (response.type === COMPETITOR_SCORE_SSE_EVENT_TYPES.receive_db_data) {
                    const responseData = JSON.parse(response.data);
                    if (!isEmpty(responseData)) {
                        yield put(setCompetitorsScore(responseData));
                    }
                }
                if (response.type === COMPETITOR_SCORE_SSE_EVENT_TYPES.receive_kw_h2_h3_scores) {
                    const responseData = JSON.parse(response.data);
                    if (!isEmpty(responseData)) {
                        yield put(setUrlKeywordsScore(responseData));
                    }
                }

                if (response.type === COMPETITOR_SCORE_SSE_EVENT_TYPES.req_completed) {
                    channel.close();
                    yield put(competitorsScoreSSELoading(COMPETITOR_SCORE_SSE_EVENT_TYPES.req_completed));
                }

                if (
                    response.type === COMPETITOR_SCORE_SSE_EVENT_TYPES.req_failed ||
                    response.type === COMPETITOR_SCORE_SSE_EVENT_TYPES.error
                ) {
                    channel.close();
                    yield put(competitorsScoreSSELoading(COMPETITOR_SCORE_SSE_EVENT_TYPES.req_failed));
                    googleTagManagerDataLayer("Recommendation Load Error", [], { recomm_tab: SIMULATOR_CONST.CONTENT_FROM_TOP });
                }
            } catch (error) {
                if (competitorScoreSSERetriedAttempt < 3) {
                    const parameter = {
                        requestId,
                        token
                    };
                    channel.close();
                    yield put(setCompetitorScoreSSeRetriedAttempt(competitorScoreSSERetriedAttempt + 1));
                    yield put(callToGetCometitorScoreSSE(parameter));
                }
                channel.close();
                yield put(competitorsScoreSSELoading(COMPETITOR_SCORE_SSE_EVENT_TYPES.error));
            }
        }
    } finally {
        if (yield cancelled()) {
            channel.close();
        }
    }
}

// tslint:disable-next-line:typedef
export function* fetchPreSimKeywordDetails(action: AnyAction) {
    const { tenantCode } = getContext();
    const { keywords, locale, url, is_url_live, notifyErrorMethod } = action.payload;
    const payload: LiveNonLivePerformanceMetricsPayloadInterface = {
        url,
        is_url_live,
        ...(keywords && { keywords }),
        ...(locale && { locale }),
        ...(notifyErrorMethod && { notifyErrorMethod }),
        isBeta: true,
        needToHandleError: true
    };
    const multiKeywordLocale = yield select(getMultiKeywordLocale);
    const contextSelectedLocale = yield select(getContextLocale);

    const keywordDetails = yield call(Apis.fetchPreSimKeywordDetails, payload, { tenantCode: tenantCode as string });
    if (keywordDetails.status === 200) {
        const allKWs = yield select(getAllKeywords);
        if (getKeywords(keywordDetails.data).length === Object.keys(allKWs).length) {
            yield put(setAllKWsProcessed(true));
        }
        yield put(setPreSimKeywordDetails(keywordDetails.data));
        yield put(setSimulationKeywords(getKeywords(keywordDetails.data)));
    } else if (keywordDetails.status === 204) {
        yield put(setPreSimKeywordDetails(keywordDetails.data));
        yield put(setSimulationKeywords(getKeywords(keywordDetails.data)));
    }

    if (keywordDetails.status === 200 || keywordDetails.status === 204) {
        const params = {
            keywords: getFormatedKeywordList(keywordDetails.data),
            locale: multiKeywordLocale ? multiKeywordLocale : contextSelectedLocale,
            sub_header: getSubHeadersFromEditor()
        };
        const isCallRecomBulbApi = validateRecomBulbSubHeaderScoresPayload(params);
        if (isCallRecomBulbApi) {
            yield put(callRecomBulbSubHeaderScores(params));
        }
    }
}

// tslint:disable-next-line: typedef
export function* fetchVersionList(action: AnyAction) {
    const formattedPayload = omit(action.payload, ["sid"]);
    const payload = {
        ...formattedPayload,
        isBeta: true
    };
    const response = yield call(Apis.getVersionList, payload as VersionListInputPayloadInterface);
    const isUrlLive = action.payload.is_url_live;
    const sid = action.payload.sid || "";
    let editorMode = EDITOR_MODES.editor;
    if (response.status === 200) {
        const resp = response.data.data;
        const vesrsionDetails = {
            versionList: resp,
            ...(!isUndefined(action.payload.sid) && { selectedVersion: action.payload.sid })
        };
        yield put(SetVersionList(vesrsionDetails));

        // Check selected simulation version is browser mode or not
        if (sid) {
            const hasBrowserMode = resp.some(
                (versionItem: { sid: string; is_browser_mode?: boolean }) => versionItem.sid === sid && versionItem.is_browser_mode
            );
            // Switch version by simulation version
            editorMode = hasBrowserMode ? EDITOR_MODES.browser : EDITOR_MODES.editor;
        } else if (isUrlLive && resp[0].is_browser_mode) {
            // Set browser mode if new simulation or latest simulation is browser mode
            editorMode = EDITOR_MODES.browser;
        }
        yield put(setEditorMode(editorMode));
    } else {
        yield put(SetVersionList([]));
    }
}
// tslint:disable-next-line: typedef
export function* fetchScoresBasedOnVersion(action: AnyAction) {
    const payload = {
        ...action.payload
    };
    const response = yield call(Apis.getScoresBasedOnVersion, payload);
    if (response.status === 200) {
        const scoreDetails = response.data;
        const scoreBasedOnVersion = {
            modelScore: scoreDetails.simulated_data && scoreDetails.simulated_data.content.aggregated_model_score,
            modelScoreDetails: {
                ...scoreDetails.simulated_data.content.model_score_details,
                category_scores: {
                    ...scoreDetails.simulated_data.content.model_score_details.category_scores,
                    ...scoreDetails.simulated_data.scores
                }
            },
            performance: scoreDetails.simulated_data.aggregated_performance_metrics,
            authorityForcedChangeParameter:
                scoreDetails &&
                scoreDetails.simulated_data &&
                scoreDetails.simulated_data.authority &&
                scoreDetails.simulated_data.authority.parameters,
            technicalForcedChangeParameter:
                scoreDetails &&
                scoreDetails.simulated_data &&
                scoreDetails.simulated_data.technical &&
                scoreDetails.simulated_data.technical.parameters,
            impactedKeywords: scoreDetails.simulated_data.keyword_data || []
        };

        yield put(setIsSimulationDone(true));
        yield put(setSimulationKeywords(getKeywords(scoreBasedOnVersion.impactedKeywords)));
        yield put(setVersionKeywords(getKeywords(scoreBasedOnVersion.impactedKeywords)));
        yield put(setSerpProgressStatusFromHistoryTable());
        yield put(setEnableSimulatePerformance(true));
        const enableSimulateScore = yield select(getEnableSimulateScore);
        if (enableSimulateScore) {
            yield put(setAllKWsProcessed(true));
        }
        yield put(setScoreBasedOnVersion(scoreBasedOnVersion));
        yield put(getSimulationMetaInfo({ sid: payload.sid }));
        yield put(getAuthTechParams({ sid: payload.sid, isBeta: true }));
        yield put(getCompetitorsScoreBasedOnVersion({ sid: payload.sid }));

        const multiKeywordLocale = yield select(getMultiKeywordLocale);
        const contextSelectedLocale = yield select(getContextLocale);
        const params = {
            keywords: getFormatedKeywordList(scoreBasedOnVersion.impactedKeywords),
            locale: multiKeywordLocale ? multiKeywordLocale : contextSelectedLocale,
            sub_header: getSubHeadersFromEditor()
        };
        const isCallRecomBulbApi = validateRecomBulbSubHeaderScoresPayload(params);
        if (isCallRecomBulbApi) {
            yield put(callRecomBulbSubHeaderScores(params));
        }
    }
}
// tslint:disable-next-line: typedef
export function* saveSnapShotMetaInformation(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const payload: MetaInformationInterface = {
        ...action.payload,
        description: action.payload.description,
        isBeta: true
    };
    const response = yield call(Apis.captureSnapshotMetaInformation, payload, parser);
    if (response.status === 201) {
        const isTrackRequestComplete = yield select(getTrackRequestStatus);
        const { sid } = action.payload;
        if (isTrackRequestComplete) {
            yield call(Apis.saveCompetitorScores, { sid, isBeta: true }, parser);
            yield put(updateSidToSaveCompetitorsScore());
        } else {
            yield put(updateSidToSaveCompetitorsScore(sid));
        }
        const versionPayload = {
            url: action.payload.url,
            is_url_live: action.payload.is_url_live,
            sid: action.payload.sid,
            tenantCode: tenantCode as string
        };
        yield put(callToGetVersionList(versionPayload));
    }
}
// tslint:disable-next-line: typedef
export function* fetchContentBasedOnVersion(action: AnyAction) {
    const payload = {
        ...action.payload,
        isBeta: true
    };
    yield put(setIsFetchContentOnSwitchEditorMode(true));
    yield put(setOriginalWordCount(undefined)); // setting original word count to 0 everytime editor content version is changed
    const onPageElements = yield call(Apis.getContentBasedOnVersion, payload);

    if (onPageElements.status === 200 || onPageElements.status === 206) {
        const formattedOnPageElements = formatOnPageElements(omit(onPageElements.data.data.orig_dict, ["content_crawl_date"]));
        const formattedChangeDict = getChangedDataBasedOnVersion(
            omit(onPageElements.data.data.orig_dict, ["content_crawl_date"]),
            onPageElements.data.data.changed_dict
        );

        yield put(setIsContentFetched(true));
        if (payload.editorMode === EDITOR_MODES.browser) {
            const contentBasedOnVersion = {
                crawl_date: onPageElements.data.data.orig_dict.content_crawl_date,
                pageContent: formattedOnPageElements,
                changedPageContent: !isEmpty(onPageElements.data) && onPageElements.data.data.changed_dict ? formattedChangeDict : undefined
            };
            yield put(setOnBrowserEditorBasedOnVersion(contentBasedOnVersion));
            yield put(setBrowserContent(onPageElements.data.data.changed_dict.stripped_page));
        } else {
            const contentBasedOnVersion = {
                crawl_date: onPageElements.data.data.orig_dict.content_crawl_date,
                pageContent: getUpdatedPageContent(formattedOnPageElements),
                changedPageContent:
                    !isEmpty(onPageElements.data) && onPageElements.data.data.changed_dict
                        ? getUpdatedPageContent(formattedChangeDict)
                        : undefined
            };
            yield put(setOnPageElementsBasedOnVersion(contentBasedOnVersion));
            const impactedKeywords = yield select(getProcessedKeywords);
            const multiKeywordLocale = yield select(getMultiKeywordLocale);
            const contextSelectedLocale = yield select(getContextLocale);
            const params = {
                keywords: getFormatedKeywordList(impactedKeywords),
                locale: multiKeywordLocale ? multiKeywordLocale : contextSelectedLocale,
                sub_header: getSubHeadersFromEditor()
            };
            const isCallRecomBulbApi = validateRecomBulbSubHeaderScoresPayload(params);
            if (isCallRecomBulbApi) {
                yield put(callRecomBulbSubHeaderScores(params));
            }
        }
    } else if (onPageElements.status === 204) {
        yield put(setIsContentFetched(true));
        const formattedOnPageElements = formatOnPageElements(DEFAULT_EDITOR_CONTENT);
        const contentBasedOnVersion = {
            crawl_date: "",
            pageContent: getUpdatedPageContent(formattedOnPageElements),
            changedPageContent: undefined
        };

        if (payload.editorMode === EDITOR_MODES.browser) {
            contentBasedOnVersion.pageContent = formattedOnPageElements;
            yield put(setOnBrowserEditorBasedOnVersion(contentBasedOnVersion));
            yield put(setBrowserContent(""));
        } else {
            yield put(setOnPageElementsBasedOnVersion(contentBasedOnVersion));
            const impactedKeywords = yield select(getProcessedKeywords);
            const multiKeywordLocale = yield select(getMultiKeywordLocale);
            const contextSelectedLocale = yield select(getContextLocale);
            const params = {
                keywords: getFormatedKeywordList(impactedKeywords),
                locale: multiKeywordLocale ? multiKeywordLocale : contextSelectedLocale,
                sub_header: getSubHeadersFromEditor()
            };
            const isCallRecomBulbApi = validateRecomBulbSubHeaderScoresPayload(params);
            if (isCallRecomBulbApi) {
                yield put(callRecomBulbSubHeaderScores(params));
            }
        }
    }

    yield put(setIsFetchContentOnSwitchEditorMode(false));
}
// tslint:disable-next-line: typedef
export function* updateDescription(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const payload: MetaInformationInterface = {
        ...action.payload,
        isBeta: true
    };
    const response = yield call(Apis.updateDescription, payload, parser);
    if (response.status === 200 || response.status === 304) {
        yield put(setIsDescriptionUpdateFailed(false));
        yield put(setSaveDescription(action.payload.description));
    } else {
        yield put(setIsDescriptionUpdateFailed(true));
    }
}


// tslint:disable-next-line:typedef
function* fetchSimMetaInfo(action: AnyAction) {
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const payload = {
        ...action.payload,
        isBeta: true
    };
    const response = yield call(Apis.getSimMetaInfo, payload, parser);
    if (response.status === 200) {
        const { keywords, description, locale } = response.data.data;
        const keywordRequestIds = getKeywordRequestIds(keywords);
        const impactedKeywords = yield select(getProcessedKeywords);
        const keywordProcessedInfo = getKeywordProcessedInfo(keywordRequestIds, impactedKeywords);
        yield put(
            updateKeywordDetailsBasedOnVersion({
                keywordRequestIds,
                keywordProcessedInfo,
                selectedKeywords: keywords
            })
        );
        const metaInfo = response.data.data;
        const isBrowserMode = isBrowserModeExist(metaInfo);
        yield put(setIsBrowserMode(isBrowserMode));
        yield put(setSaveDescription(description));
        yield put(updateProcessedKeywordsOnVersionChange());
        yield put(selectedMultiKeywordLocale(locale));
        yield put(setMetaInfoFetchStatus(true));
        const multiKeywordLocale = yield select(getMultiKeywordLocale);
        yield put(callToGetCompetitorsScoreSSEReqid({ sid: payload.sid, locale: multiKeywordLocale }));

        const params = {
            keywords: getFormatedKeywordList(impactedKeywords),
            locale: multiKeywordLocale,
            sub_header: getSubHeadersFromEditor()
        };
        const isCallRecomBulbApi = validateRecomBulbSubHeaderScoresPayload(params);
        if (isCallRecomBulbApi) {
            yield put(callRecomBulbSubHeaderScores(params));
        }
    }
}

// tslint:disable-next-line:typedef
function* fetchVersionCompetitorScores(action: AnyAction) {
    const { tenantCode } = getContext();
    const payload = {
        sid: action.payload.sid,
        isBeta: true
    };
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.getVersionCompetitorScores, payload, parser);
    if (response.status === 200 || response.status === 206) {
        if (!isEmpty(response.data.data)) {
            yield put(setCompetitorsScore(response.data.data));
        }
    }
}

// tslint:disable-next-line:typedef
function* fetchSimulationVersionStatus(action: AnyAction) {
    const payload = {
        sid: action.payload,
        isBeta: true
    };
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.getSimulationVersionStatus, payload, parser);
    if (response.status === 200) {
        yield put(setSimulationVersionStatus(response.data));
    }
}

// tslint:disable-next-line:typedef
function* fetchSimulationDownloadApi(action: AnyAction) {
    yield put(setIsDownloadSimulation(true));
    const payload = action.payload;
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.getSimulationDownload, payload, parser);

    if (response.status === 200) {
        const changeContent = yield select(getPageChangedContent);
        const pageContent = yield select(getPageContent);
        const content = changeContent && changeContent.length > 0 ? changeContent : pageContent;
        const fileName = getTitle(content, SIMULATION_DOWNLOAD_FILE_NAME, SIMULATION_DOWNLOAD_PERFIX);
        yield put(setIsDownloadSimulation(false));
        downloadSimulationContent(response.data, fileName);
    } else {
        yield put(setIsDownloadSimulation(false));
        yield put(setIsDownloadFailed(true));
    }
}

// tslint:disable-next-line:typedef
function* fetchSimulationDownloadContentOnlyApi(action: AnyAction) {
    yield put(setIsDownloadSimulation(true));
    const payload = action.payload;
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.getSimulationDownloadContentOnly, payload, parser);

    if (response.status === 200) {
        const changeContent = yield select(getPageChangedContent);
        const pageContent = yield select(getPageContent);
        const content = changeContent && changeContent.length > 0 ? changeContent : pageContent;
        const fileName = getTitle(content, SIMULATION_DOWNLOAD_CONTENT_FILE_NAME, "");
        yield put(setIsDownloadSimulation(false));
        downloadSimulationContent(response.data, fileName);
    } else {
        yield put(setIsDownloadSimulation(false));
        yield put(setIsDownloadFailed(true));
    }
}

// tslint:disable-next-line:typedef
function* fetchSimulationDownloadContentOnlyInitiateApi(action: AnyAction) {
    yield put(setIsDownloadSimulation(true));
    const payload = action.payload;
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.getSimulationDownloadContentOnlyInitiate, payload, parser);

    if (response.status === 202) {
        const reqPayload = {
            sid: payload.sid,
            req_id: response.data.request_id,
            count: 0
        };
        yield put(callSimulationDownloadContentOnlyTrackApi(reqPayload));
    } else {
        yield put(setIsDownloadSimulation(false));
        yield put(setIsDownloadFailed(true));
    }
}

// tslint:disable-next-line:typedef
function* fetchSimulationDownloadContentOnlyTrackApi(action: AnyAction) {
    if (window.location.pathname !== "/simulation") {
        yield cancel();
    }
    const payload = {
        sid: action.payload.sid,
        req_id: action.payload.req_id,
    };
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.getSimulationDownloadContentOnlyTrack, payload, parser);

    if (response.status === 200) {
        const changeContent = yield select(getPageChangedContent);
        const pageContent = yield select(getPageContent);
        const content = changeContent && changeContent.length > 0 ? changeContent : pageContent;
        const fileName = getTitle(content, SIMULATION_DOWNLOAD_CONTENT_FILE_NAME, "");
        yield put(setIsDownloadSimulation(false));
        downloadSimulationContent(response.data, fileName);
    } else if (response.status === 204) {
        const count = action.payload.count;
        yield delay(FIB_REQ_DELAY[count] * 1000);
        const reqPayload = {
            sid: action.payload.sid,
            req_id: action.payload.req_id,
            count: count === 6 ? 6 : count + 1
        };
        yield put(callSimulationDownloadContentOnlyTrackApi(reqPayload));
    } else {
        yield put(setIsDownloadSimulation(false));
        yield put(setIsDownloadFailed(true));
    }
}

// tslint:disable-next-line:typedef
function* fetchSimulationDownloadPageAssessmentApi(action: AnyAction) {
    yield put(setIsDownloadPageAssessment(true));
    const payload = action.payload;
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };
    const response = yield call(Apis.downloadSimulationPageAssessment, payload, parser);

    if (response.status === 200) {
        yield put(setIsDownloadPageAssessment(false));
        downloadSimulationContent(response.data, SIMULATION_DOWNLOAD_ASSESSMENT_FILE_NAME);
    } else {
        yield put(setIsDownloadPageAssessment(false));
        yield put(setIsDownloadPAFailed(true));
    }
}

// tslint:disable-next-line:typedef
function* fetchRecomBulbSubHeaderScores(action: AnyAction) {
    const payload = action.payload;
    const { tenantCode } = getContext();
    const parser = {
        tenantCode: tenantCode as string
    };

    const response = yield call(Apis.postRecomBulbSubHeaderScores, payload, parser);
    if (response.status === 200) {
        yield put(setRecomBulbSubHeaderScores(response.data));
    }
}

// tslint:disable-next-line:typedef
export function* watchForModelScoreCall() {
    yield takeEvery(actionTypes.CALL_TO_GET_MODEL_SCORE, fetchModelScore);
}
// tslint:disable-next-line:typedef
export function* watchForKeywordStuffingCall() {
    yield takeEvery(actionTypes.CALL_TO_GET_KEYWORD_STUFFING_DETAILS, fetchKeywordStuffingDetail);
}
// tslint:disable-next-line:typedef
export function* watchForModelScoreDetailCall() {
    yield takeEvery(actionTypes.CALL_TO_GET_MODEL_SCORE_DETAILS, fetchModelScoreDetails);
}

// tslint:disable-next-line:typedef
export function* watchForPerformanceScoreCall() {
    yield takeEvery(actionTypes.CALL_TO_GET_PERFORMANCE_SCORE, fetchPerformanceScore);
}

// tslint:disable-next-line:typedef
export function* watchForOnPageElementsCall() {
    yield takeEvery(actionTypes.CALL_TO_GET_ON_PAGE_ELEMENTS, fetchOnPageElements);
}

// tslint:disable-next-line:typedef
export function* watchforPostSimulationCall() {
    yield takeEvery(actionTypes.SET_CHANGED_CONTENT, fetchPostSimulationScores);
    yield takeEvery(actionTypes.SET_CHANGED_BROWSER_EDITOR_CONTENT, fetchPostSimulationScores);
}

// tslint:disable-next-line:typedef
export function* watchForCompetitorsScoreCall() {
    yield takeEvery(actionTypes.CALL_TO_GET_COMPETITORS_SCORE, fetchCompetitorsScore);
}

// tslint:disable-next-line:typedef
export function* watchforPreSimKeywordLevelDetails() {
    yield takeEvery(actionTypes.CALL_TO_GET_PRE_SIM_KEYWORD_LEVEL_DETAILS, fetchPreSimKeywordDetails);
}
// tslint:disable-next-line: typedef
export function* watchForVersionListCall() {
    yield takeEvery(actionTypes.CALL_TO_GET_VERSION_LIST, fetchVersionList);
}
// tslint:disable-next-line: typedef
export function* watchForScoresBasedOnVersionCall() {
    yield takeEvery(actionTypes.CALL_SCORE_API_BASED_ON_VERSION, fetchScoresBasedOnVersion);
}

// tslint:disable-next-line: typedef
export function* watchForSnapShotMetaInformation() {
    yield takeEvery(actionTypes.CALL_TO_CAPTURE_SNAPSHOT_OF_META_INFORMATION, saveSnapShotMetaInformation);
}
// tslint:disable-next-line: typedef
export function* watchForContentBasedOnVersionCall() {
    yield takeEvery(actionTypes.CALL_CONTENT_API_BASED_ON_VERSION, fetchContentBasedOnVersion);
}

// tslint:disable-next-line: typedef
export function* watchForUpdateDescription() {
    yield takeEvery(actionTypes.CALL_TO_SAVE_DESCRIPTION, updateDescription);
}

// tslint:disable-next-line: typedef
export function* watchForSimulationMetaInfo() {
    yield takeEvery(actionTypes.GET_SIMULATION_META_INFO, fetchSimMetaInfo);
}

// tslint:disable-next-line: typedef
export function* watchForCompetitorScoresBasedOnVersionCall() {
    yield takeEvery(actionTypes.GET_COMPETITORS_SCORE_BASED_ON_VERSION, fetchVersionCompetitorScores);
}

// tslint:disable-next-line:typedef
export function* watchForSimulationVersionStatus() {
    yield takeEvery(actionTypes.GET_SIMULATION_VERSION_STATUS, fetchSimulationVersionStatus);
}

// tslint:disable-next-line: typedef
export function* watchGetSimulationDownload() {
    yield takeEvery(actionTypes.CALL_SIMULATION_DOWNLOAD_API, fetchSimulationDownloadApi);
}

// tslint:disable-next-line: typedef
export function* watchGetSimulationContentOnlyDownload() {
    yield takeEvery(actionTypes.CALL_SIMULATION_DOWNLOAD_CONTENT_ONLY_API, fetchSimulationDownloadContentOnlyApi);
}

// tslint:disable-next-line: typedef
export function* watchGetSimulationContentOnlyInitiateDownload() {
    yield takeEvery(actionTypes.CALL_SIMULATION_DOWNLOAD_CONTENT_ONLY_INITIATE_API, fetchSimulationDownloadContentOnlyInitiateApi);
}

// tslint:disable-next-line: typedef
export function* watchGetSimulationContentOnlyTrackDownload() {
    yield takeEvery(actionTypes.CALL_SIMULATION_DOWNLOAD_CONTENT_ONLY_TRACK_API, fetchSimulationDownloadContentOnlyTrackApi);
}

// tslint:disable-next-line: typedef
export function* watchPageAssessmentDownload() {
    yield takeEvery(actionTypes.CALL_SIMULATION_DOWNLOAD_PAGE_ASSESSMENT_API, fetchSimulationDownloadPageAssessmentApi);
}

// tslint:disable-next-line: typedef
export function* watchGetCompetitorScoreSSEReqId() {
    yield takeEvery(actionTypes.CALL_TO_GET_COMPETITOR_SCORE_SSE_REQID, callToGetCompetitorsScoreSSEReqId);
}

// tslint:disable-next-line: typedef
export function* watchGetCompetitorScoreSSE() {
    yield takeEvery(actionTypes.CALL_TO_GET_COMPETITOR_SCORE_SSE, callToGetCometitorScoreAPISSE);
}

// tslint:disable-next-line: typedef
export function* watchGetCompetitiorScoreNewKeywordReqId() {
    yield takeEvery(actionTypes.CALL_TO_GET_COMPETITOR_SCORE_NEW_KEYWORD, callToGetCompetitorsScoreNewKeywordSSEReqId);
}

// tslint:disable-next-line: typedef
export function* watchGetRecomBulbSubHeaderScores() {
    yield takeEvery(actionTypes.CALL_RECOM_BULB_SUBHEADER_SCORES, fetchRecomBulbSubHeaderScores);
}
